//
// section.scss
// Incline component
//

// General
// =======

.section {
  position: relative;
  padding: $section-padding-y-sm 0;

  @include media-breakpoint-up(md) {
    padding: $section-padding-y 0;
  }

  // Container
  // =========

  .container {
    position: relative;
    z-index: 1;
  }


  // Top
  // ===

  &-top {
    padding-top: calc(#{$section-padding-y-sm} + #{$navbar-height});

    @include media-breakpoint-up(md) {
      padding-top: calc(#{$section-padding-y} + #{$navbar-height});
    }
  }


  // Full
  // ====

  &-full {

    @include media-breakpoint-up(md) {

      // Scaffolding

      .container {

        .row {
          min-height: calc(100vh - #{$section-padding-y} * 2);

          &:before {
            content: '';
            min-height: inherit;
          }
        }
      }

      // Top

      &.section-top {

        > .container {

          > .row {
            min-height: calc(100vh - #{$section-padding-y} * 2 - #{$navbar-height});
          }
        }
      }
    }
  }
}