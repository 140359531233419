.navbar {
  &.navbar-light {
    .navbar-brand {
      .navbar-brand-svg {
        fill: #000;
        stroke: #000;
      }
    }

    .nav-item {
      .nav-link {
        &.bordered {
          border: 2px solid #000;
        }
      }
    }
  }

  &.navbar-dark {
    .navbar-brand {
      .navbar-brand-svg {
        fill: #fff;
        stroke: #fff;
      }
    }
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: start;

    > * {
      min-width: 0;
    }

    .navbar-brand-img {
      height: 3rem;
    }

    .navbar-brand-svg {
      width: auto;
      width: 148px;
      height: 45px;

      @include media-breakpoint-down(xs) {
        width: 100px;
        height: 30px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 5px 20px;
  }

  .nav-item {
    .nav-link {
      &.bordered {
        border: 2px solid #fff;
        padding: 10px 20px;
        margin-top: 4px;
      }
    }
  }

  &.navbar-static {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;

    .container {
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(xs) {
        padding: 0;
      }
    }

    .navbar-brand {
      .navbar-brand-svg {
        fill: #fff;
        stroke: #fff;
      }
    }

    .phone-link {
      color: #fff;
      font-size: 18px;

      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }

      &:hover,
      &:focus {
        color: darken(#fff, .2);
        text-decoration: none;
      }
    }
  }
}
