@mixin dropdown-defaults {
  :global {
    .dropdown {
      position: relative;
      display: inline-block;
    }

    .dropdown__trigger {
      cursor: pointer;
      user-select: none;
    }

    .dropdown__content {
      display: none;
      position: absolute;
    }

    .dropdown--active {
      .dropdown__content {
        display: block;
      }
    }
  }
}
