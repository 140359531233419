/*
@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
@import 'bootstrap/print';
@import 'bootstrap/reboot';
@import 'bootstrap/type';
@import 'bootstrap/images';
@import 'bootstrap/code';
@import 'bootstrap/grid';
@import 'bootstrap/tables'; //
@import 'bootstrap/forms'; //
@import 'bootstrap/buttons';
@import 'bootstrap/transitions';
@import 'bootstrap/dropdown'; //
@import 'bootstrap/button-group';
@import 'bootstrap/input-group';
@import 'bootstrap/custom-forms'; //
@import 'bootstrap/nav';
@import 'bootstrap/navbar';
@import 'bootstrap/card';
@import 'bootstrap/breadcrumb'; //
@import 'bootstrap/pagination'; //
@import 'bootstrap/badge'; //
@import 'bootstrap/jumbotron'; //
@import 'bootstrap/alert'; //
@import 'bootstrap/progress'; //
@import 'bootstrap/media';
@import 'bootstrap/list-group';
@import 'bootstrap/close';
@import 'bootstrap/modal';
@import 'bootstrap/tooltip'; //
@import 'bootstrap/popover';
@import 'bootstrap/carousel'; //
@import 'bootstrap/utilities';
*/

@import 'bootstrap/bootstrap';
@import 'theme-brown';
@import 'shared';
@import 'common/common';
@import 'partials/partials';
@import 'pages/pages';
@import 'pages/swot';
//@import 'theme';
@import 'custom';

.loading {
    z-index: 10;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
  }

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
  }