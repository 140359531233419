footer.section {
  .footer-brand {
    .footer-brand-svg {
      width: auto;
      width: 148px;
      height: 45px;
      fill: #fff;
      stroke: #fff;
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 40px 0;
  }
}
