.form-submit-button {
  outline: none;
  box-shadow: none;
  border: 0;
  transition: all .3s ease;

  &:hover {
    opacity: .8;
  }
}
