/*
# TABLE OF CONTENT
00. GLOBALS
01. BLOG INDEX
02. BLOG POST
*/

/* 00. GLOBALS */
$custom-color__primary: #d2aa78;
$custom-color__primary--dark: #be863f;
$custom-color__primary--light: #f5ba4b;

$custom-color__text: rgb(33, 37, 41);
$custom-color__text--muted: rgba(0, 0, 0, 0.2);

$custom-break--md: 768px;

.avatar img {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  object-fit: cover;
}

.featured-card-wrapper {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (max-width: $custom-break--md) {
  .featured-card-wrapper {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

/* 01. BLOG INDEX */
// HERO SECTION
.post-img-wrapper {
  text-align: center;
}

.img-incline-left {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
}

// LATEST POSTS
.category-btn {
  cursor: pointer;
}

.category-btn--active,
.category-btn:hover {
  cursor: pointer;
  color: $custom-color__primary--dark;
}

@media (max-width: $custom-break--md) {
  #post-categories .border-left {
    border: none !important;
  }
}

// PAGINATION
.paging {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paging--hide {
  display: none !important;
}

.paging__body {
  display: flex;
  align-items: center;
}

.paging li {
  list-style: none;
  width: 1.75rem;
  height: 100%;
}

.paging li a {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
  color: $custom-color__text;
}

.paging li a:hover {
  color: $custom-color__primary--dark;
  border-color: $custom-color__primary--dark;
  text-decoration: none;
}

.paging li.active a {
  color: $custom-color__primary--dark;
}

.paging li.disabled a {
  cursor: default;
  color: $custom-color__text;
}

.paging__arrow::after {
  content: "";

  display: inline-block;
  width: 8px;
  height: 8px;
  border-left: 2px solid $custom-color__text;
  border-bottom: 2px solid $custom-color__text;
}

.paging__arrow:hover.paging__arrow::after {
  cursor: pointer;
  border-color: $custom-color__primary--dark;
}

.paging__arrow--left::after {
  transform: rotate(45deg);
}

.paging__arrow--right::after {
  transform: rotate(225deg);
}

.paging__arrow--disabled::after {
  cursor: default;
  border-color: $custom-color__text--muted;
}

.paging__arrow--disabled:hover,
.paging__arrow--disabled:hover.paging__arrow::after {
  cursor: default;
  border-color: $custom-color__text--muted;
}

/* 02. BLOG POST */
// CONTENT
.blog-post {
  blockquote {
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    border-left: 1px solid $custom-color__primary--light;
    font-size: 1rem;
  }

  img {
    margin: 0 auto;
    display: block;
  }

  picture {
    margin: 0 auto;
    display: block;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    scroll-margin: 75px;

    @media (min-width: 576px) {
      scroll-margin: 125px;
    }
  }
}

.blog-post-social-link {
  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

// IMAGE EFFECT Z INDEX
.img-effect-border,
.img-effect-solid,
.img-effect-dotted {
  &:before,
  &:after {
    z-index: -1;
  }
}

[data-reveal] {
  opacity: 0;
}

.reveal-item {
  transform: translateY(10px);
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

mark {
  background-color: #ffe2bf;
}

.search {
  display: flex;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: 1rem;
    }
  }

  .search__search-group {
    display: flex;
    margin-right: 2rem;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    .search__icon {
      height: 3.5rem;
    }

    .search__field {
      height: 3.5rem;
    }

    .search__submit {
      height: 3.5rem;
    }
  }

  .search__select-group {
    display: flex;

    @media (max-width: 768px) {
      width: 100%;
    }

    .search__select-group__item {
      & + .search__select-group__item {
        margin-left: 1rem;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.search-modal {
  backdrop-filter: blur(5px);
}