//
// stats.scss
// Incline component
//

.stats {
  
  // Item
  // ====

  &-item {
    position: relative;
    
    // Line

    &:before {
      content: '';
      display: block;
      width: .5rem; height: .5rem;
      margin: 0 auto 1.5rem;
      background-color: $primary;
    }

    // Value

    &-value {
      font-family: $stats-item-val-font-family;
      font-size: $stats-item-val-font-size;
    }

    // Title

    &-title {
      font-size: $stats-item-title-font-size;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }
}