//
// pagination.scss
// Extended from Bootstrap
//

.page {

  // Link
  // ====

  &-link {
    font-size: $font-size-xs !important;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
  
}