.section-top {
  .bg-overlay {
    &:before {
      opacity: .70;
    }
  }

  h1 {
    @include media-breakpoint-up(xl) {
      font-size: 60px;
    }
  }
}

.section {
  @include media-breakpoint-down(xs) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  #more {display: none;}

  &.hero-section {
    @include media-breakpoint-down(xs) {
      padding-top: 120px;
      padding-bottom: 50px;
    }
  }

  &.section-full {
    @include media-breakpoint-down(xs) {
      padding-top: 200px;
      padding-top: 35vh;
      padding-bottom: 100px;
      padding-bottom: 25vh;
      min-height: 100vh;
    }
  }
}

.contact-form {
  .hs-form-iframe {
    max-width: 100%;
  }

  .form-wrap,
  .form-wrap-block {
    position: relative;
    left: 20px;
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;

    .hs-form-iframe {
      width: 100% !important;
    }
  }

  .form-group {
    .field-label {
      width: 100%;
      font-size: 14px;
      margin-bottom: 5px;
      text-align: left;
      color: #000;
    }
  }
}

.static-contact-section {
  min-height: 100vh;

  @include media-breakpoint-up(lg) {
    padding-top: 10rem;
  }

  .main-title,
  .sub-title {
    color: #fff;
  }

  .main-title {
    font-size: 50px;

    @include media-breakpoint-down(md) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  .sub-title {
    font-size: 40px;

    @include media-breakpoint-down(md) {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }

  .title-col {
    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    &.full-width-call {
      padding-top: 50px;
    }
  }

  .form-block {
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    background-color: #fff;
  }

  .form-wrap-block,
  .hs-form-iframe {
    width: 100% !important;
  }

  .form-wrap-block {
    padding: 30px 10%;

    &.hubspot-block {
      padding-right: 0;
    }
  }

  .list-iconed {
    margin-top: 10px;
  }

}

.bank-hero-bg {
  @include media-breakpoint-up(sm) {
    background-image: url('../img/backgrounds/bank-hero.png');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../img/backgrounds/bank-hero.png');
  }
}

.GA4-hero-bg {
  @include media-breakpoint-up(sm) {
    background-image: url('../img/backgrounds/GA4-hero-bg.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../img/backgrounds/GA4-hero-bg.jpg');
  }
}

.homepage-hero-bg {
  @include media-breakpoint-up(sm) {
    background-image: url('../img/backgrounds/home-hero-bg2.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../img/backgrounds/home-hero-bg2-mobile.jpg');
  }
}

.landing-hero-bg {
  @include media-breakpoint-up(sm) {
    background-image: url('../img/backgrounds/landing-hero-bg.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../img/backgrounds/landing-hero-bg-mobile.jpg');
  }
}

.data-hero-bg {
  @include media-breakpoint-up(sm) {
    background-image: url('../img/backgrounds/data-hero-bg.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../img/backgrounds/data-hero-bg-mobile.jpg');
  }
}

.dev-hero-bg {
  @include media-breakpoint-up(sm) {
    background-image: url('../img/backgrounds/dev-hero-bg.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../img/backgrounds/dev-hero-bg-mobile.jpg');
  }
}

.kiosk-hero-bg {
  @include media-breakpoint-up(sm) {
    background-image: url('../img/backgrounds/kiosk2-bg.jpg');
  }

  @include media-breakpoint-down(xs) {
    background-image: url('../img/backgrounds/kiosk2-bg.jpg');
  }
}
