@charset 'UTF-8';
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
//:root {
//  --blue: #007bff;
//  --indigo: #6610f2;
//  --purple: #6f42c1;
//  --pink: #e83e8c;
//  --red: #dc3545;
//  --orange: #fd7e14;
//  --yellow: #ffc107;
//  --green: #28a745;
//  --teal: #20c997;
//  --cyan: #17a2b8;
//  --white: #fff;
//  --gray: #828a91;
//  --gray-dark: #343a40;
//  --primary: #d2aa78;
//  --secondary: #828a91;
//  --success: #28a745;
//  --info: #17a2b8;
//  --warning: #ffc107;
//  --danger: #dc3545;
//  --light: #f8f9fa;
//  --dark: #343a40;
//  --breakpoint-xs: 0;
//  --breakpoint-sm: 576px;
//  --breakpoint-md: 768px;
//  --breakpoint-lg: 992px;
//  --breakpoint-xl: 1200px;
//  --font-family-sans-serif: 'Objective', sans-serif;
//  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
//}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;

  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  font-family: 'Objective', sans-serif;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.75;

  margin: 0;

  text-align: left;

  color: #212529;
  background-color: #fff;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  overflow: visible;

  box-sizing: content-box;
  height: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  text-decoration: underline;
  text-decoration: underline dotted;

  border-bottom: 0;

  -webkit-text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;

  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;

  position: relative;

  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  text-decoration: none;

  color: #d2aa78;
  background-color: transparent;

  -webkit-text-decoration-skip: objects;
}

a:hover {
  text-decoration: underline;

  color: #be863f;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;

  color: inherit;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  text-decoration: none;

  color: inherit;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em;
}

pre {
  overflow: auto;

  margin-top: 0;
  margin-bottom: 1rem;

  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;

  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;

  caption-side: bottom;

  text-align: left;

  color: #828a91;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;

  margin-bottom: .5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;

  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;

  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;

  resize: vertical;
}

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;

  border: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;

  display: block;

  width: 100%;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: 0;

  white-space: normal;

  color: inherit;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;

  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;

  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;

  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Objective', sans-serif;
  font-weight: 500;
  line-height: 1.35;

  margin-bottom: .5rem;

  color: #212529;
}

h1,
.h1 {
  font-size: 2.125rem;
}

h2,
.h2 {
  font-size: 1.625rem;
}

h3,
.h3 {
  font-size: 1.375rem;
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: .9375rem;
}

h6,
.h6 {
  font-size: .75rem;
}

.lead {
  font-size: 1.0625rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.35;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.35;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.35;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.35;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;

  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

small,
.small {
  font-size: 86.666%;
  font-weight: 400;
}

mark,
.mark {
  padding: .2em;

  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;

  list-style: none;
}

.list-inline {
  padding-left: 0;

  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  font-size: 90%;

  text-transform: uppercase;
}

.blockquote {
  font-size: 1.0625rem;

  margin-bottom: 1rem;
}

.blockquote-footer {
  font-size: 80%;

  display: block;

  color: #828a91;
}

.blockquote-footer::before {
  content: '\2014 \00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  padding: .25rem;

  border: 1px solid #dee2e6;
  background-color: #fff;
}

.figure {
  display: inline-block;
}

.figure-img {
  line-height: 1;

  margin-bottom: .5rem;
}

.figure-caption {
  font-size: 90%;

  color: #828a91;
}

code {
  font-size: 87.5%;

  word-break: break-word;

  color: #e83e8c;
}

a > code {
  color: inherit;
}

kbd {
  font-size: 87.5%;

  padding: .2rem .4rem;

  color: #fff;
  background-color: #212529;
}

kbd kbd {
  font-size: 100%;
  font-weight: 500;

  padding: 0;
}

pre {
  font-size: 87.5%;

  display: block;

  color: #212529;
}

pre code {
  font-size: inherit;

  word-break: normal;

  color: inherit;
}

.pre-scrollable {
  overflow-y: scroll;

  max-height: 340px;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.row {
  display: flex;

  margin-right: -1.5rem;
  margin-left: -1.5rem;

  flex-wrap: wrap;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;

  width: 100%;
  min-height: 1px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.col {
  max-width: 100%;

  flex-basis: 0;
  flex-grow: 1;
}

.col-auto {
  width: auto;
  max-width: none;

  flex: 0 0 auto;
}

.col-1 {
  max-width: 8.33333%;

  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.66667%;

  flex: 0 0 16.66667%;
}

.col-3 {
  max-width: 25%;

  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.33333%;

  flex: 0 0 33.33333%;
}

.col-5 {
  max-width: 41.66667%;

  flex: 0 0 41.66667%;
}

.col-6 {
  max-width: 50%;

  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.33333%;

  flex: 0 0 58.33333%;
}

.col-8 {
  max-width: 66.66667%;

  flex: 0 0 66.66667%;
}

.col-9 {
  max-width: 75%;

  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.33333%;

  flex: 0 0 83.33333%;
}

.col-11 {
  max-width: 91.66667%;

  flex: 0 0 91.66667%;
}

.col-12 {
  max-width: 100%;

  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }
  .col-sm-auto {
    width: auto;
    max-width: none;

    flex: 0 0 auto;
  }
  .col-sm-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }
  .col-sm-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }
  .col-sm-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }
  .col-sm-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }
  .col-sm-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }
  .col-sm-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }
  .col-sm-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }
  .col-sm-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }
  .col-sm-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }
  .col-sm-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }
  .col-sm-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }
  .col-sm-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }
  .col-md-auto {
    width: auto;
    max-width: none;

    flex: 0 0 auto;
  }
  .col-md-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }
  .col-md-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }
  .col-md-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }
  .col-md-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }
  .col-md-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }
  .col-md-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }
  .col-md-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }
  .col-md-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }
  .col-md-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }
  .col-md-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }
  .col-md-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }
  .col-md-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }
  .col-lg-auto {
    width: auto;
    max-width: none;

    flex: 0 0 auto;
  }
  .col-lg-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }
  .col-lg-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }
  .col-lg-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }
  .col-lg-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }
  .col-lg-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }
  .col-lg-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }
  .col-lg-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }
  .col-lg-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }
  .col-lg-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }
  .col-lg-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }
  .col-lg-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }
  .col-lg-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;

    flex-basis: 0;
    flex-grow: 1;
  }
  .col-xl-auto {
    width: auto;
    max-width: none;

    flex: 0 0 auto;
  }
  .col-xl-1 {
    max-width: 8.33333%;

    flex: 0 0 8.33333%;
  }
  .col-xl-2 {
    max-width: 16.66667%;

    flex: 0 0 16.66667%;
  }
  .col-xl-3 {
    max-width: 25%;

    flex: 0 0 25%;
  }
  .col-xl-4 {
    max-width: 33.33333%;

    flex: 0 0 33.33333%;
  }
  .col-xl-5 {
    max-width: 41.66667%;

    flex: 0 0 41.66667%;
  }
  .col-xl-6 {
    max-width: 50%;

    flex: 0 0 50%;
  }
  .col-xl-7 {
    max-width: 58.33333%;

    flex: 0 0 58.33333%;
  }
  .col-xl-8 {
    max-width: 66.66667%;

    flex: 0 0 66.66667%;
  }
  .col-xl-9 {
    max-width: 75%;

    flex: 0 0 75%;
  }
  .col-xl-10 {
    max-width: 83.33333%;

    flex: 0 0 83.33333%;
  }
  .col-xl-11 {
    max-width: 91.66667%;

    flex: 0 0 91.66667%;
  }
  .col-xl-12 {
    max-width: 100%;

    flex: 0 0 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;

  background-color: transparent;
}

.table th,
.table td {
  padding: .75rem;

  vertical-align: top;

  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;

  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: .3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, .075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f2e7d9;
}

.table-hover .table-primary:hover {
  background-color: #ecdbc6;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #ecdbc6;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dcdee0;
}

.table-hover .table-secondary:hover {
  background-color: #ced1d4;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #ced1d4;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, .075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, .075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, .075);
}

.table .thead-dark th {
  color: #fff;
  border-color: #32383e;
  background-color: #212529;
}

.table .thead-light th {
  color: #495057;
  border-color: #dee2e6;
  background-color: #e9ecef;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, .05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, .075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    overflow-x: auto;

    width: 100%;

    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  overflow-x: auto;

  width: 100%;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  font-size: .9375rem;
  line-height: 1.20313rem;

  display: block;

  width: 100%;
  padding: 1rem .75rem;

  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 0;
  background-color: #f8f9fa;
  background-clip: padding-box;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control:focus {
  color: #495057;
  border-color: #d2aa78;
  outline: 0;
  background-color: #f8f9fa;
  box-shadow: none;
}

.form-control::-webkit-input-placeholder {
  opacity: 1;
  color: #828a91;
}

.form-control:-ms-input-placeholder {
  opacity: 1;
  color: #828a91;
}

.form-control::-ms-input-placeholder {
  opacity: 1;
  color: #828a91;
}

.form-control::placeholder {
  opacity: 1;
  color: #828a91;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(3.64063rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #f8f9fa;
}

.form-control-file,
.form-control-range {
  display: block;

  width: 100%;
}

.col-form-label {
  font-size: inherit;
  line-height: 1.20313rem;

  margin-bottom: 0;
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
}

.col-form-label-lg {
  font-size: 1.0625rem;
  line-height: 1.5;

  padding-top: calc(1.25rem + 1px);
  padding-bottom: calc(1.25rem + 1px);
}

.col-form-label-sm {
  font-size: .8125rem;
  line-height: 1.5;

  padding-top: calc(.75rem + 1px);
  padding-bottom: calc(.75rem + 1px);
}

.form-control-plaintext {
  line-height: 1.20313rem;

  display: block;

  width: 100%;
  margin-bottom: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;

  color: #212529;
  border: solid transparent;
  border-width: 1px 0;
  background-color: transparent;
}

.form-control-plaintext.form-control-sm,
.input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  font-size: .8125rem;
  line-height: 1.5;

  padding: .75rem 1.25rem;
}

select.form-control-sm:not([size]):not([multiple]),
.input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.71875rem + 2px);
}

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  font-size: 1.0625rem;
  line-height: 1.5;

  padding: 1.25rem 1.75rem;
}

select.form-control-lg:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(4.09375rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;

  margin-top: .25rem;
}

.form-row {
  display: flex;

  margin-right: -5px;
  margin-left: -5px;

  flex-wrap: wrap;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;

  display: block;

  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;

  margin-top: .3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #828a91;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;

  margin-right: .75rem;
  padding-left: 0;

  align-items: center;
}

.form-check-inline .form-check-input {
  position: static;

  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0;
}

.valid-feedback {
  font-size: 86.666%;

  display: none;

  width: 100%;
  margin-top: .25rem;

  color: #28a745;
}

.valid-tooltip {
  font-size: .875rem;
  line-height: 1;

  position: absolute;
  z-index: 5;
  top: 100%;

  display: none;

  max-width: 100%;
  margin-top: .1rem;
  padding: .5rem;

  color: #fff;
  border-radius: .2rem;
  background-color: rgba(40, 167, 69, .8);
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated
.custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated
.custom-select:valid ~ .valid-feedback,
.was-validated
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before,
.custom-file-input.is-valid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.invalid-feedback {
  font-size: 86.666%;

  display: none;

  width: 100%;
  margin-top: .25rem;

  color: #dc3545;
}

.invalid-tooltip {
  font-size: .875rem;
  line-height: 1;

  position: absolute;
  z-index: 5;
  top: 100%;

  display: none;

  max-width: 100%;
  margin-top: .1rem;
  padding: .5rem;

  color: #fff;
  border-radius: .2rem;
  background-color: rgba(220, 53, 69, .8);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated
.custom-select:invalid ~ .invalid-feedback,
.was-validated
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before,
.custom-file-input.is-invalid ~ .custom-file-label::before {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.form-inline {
  display: flex;

  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;

    margin-bottom: 0;

    align-items: center;
    justify-content: center;
  }
  .form-inline .form-group {
    display: flex;

    margin-bottom: 0;

    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
  }
  .form-inline .form-control {
    display: inline-block;

    width: auto;

    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;

    width: auto;
    padding-left: 0;

    align-items: center;
    justify-content: center;
  }
  .form-inline .form-check-input {
    position: relative;

    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.75;

  display: inline-block;

  padding: 1rem 1.5rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  border: 1px solid transparent;
  border-radius: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover,
.btn:focus {
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #212529;
  border-color: #d2aa78;
  background-color: #d2aa78;
}

.btn-primary:hover {
  color: #212529;
  border-color: #c59252;
  background-color: #c8985b;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 .2rem rgba(210, 170, 120, .5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #212529;
  border-color: #d2aa78;
  background-color: #d2aa78;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #212529;
  border-color: #c28c48;
  background-color: #c59252;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(210, 170, 120, .5);
}

.btn-secondary {
  color: #fff;
  border-color: #828a91;
  background-color: #828a91;
}

.btn-secondary:hover {
  color: #fff;
  border-color: #697077;
  background-color: #6f777e;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  border-color: #828a91;
  background-color: #828a91;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  border-color: #636a70;
  background-color: #697077;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-success {
  color: #fff;
  border-color: #28a745;
  background-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  border-color: #1e7e34;
  background-color: #218838;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  border-color: #28a745;
  background-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  border-color: #1c7430;
  background-color: #1e7e34;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.btn-info {
  color: #fff;
  border-color: #17a2b8;
  background-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  border-color: #117a8b;
  background-color: #138496;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  border-color: #17a2b8;
  background-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  border-color: #10707f;
  background-color: #117a8b;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.btn-warning {
  color: #212529;
  border-color: #ffc107;
  background-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  border-color: #d39e00;
  background-color: #e0a800;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  border-color: #ffc107;
  background-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  border-color: #c69500;
  background-color: #d39e00;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-danger {
  color: #fff;
  border-color: #dc3545;
  background-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  border-color: #bd2130;
  background-color: #c82333;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  border-color: #dc3545;
  background-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  border-color: #b21f2d;
  background-color: #bd2130;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.btn-light {
  color: #212529;
  border-color: #f8f9fa;
  background-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  border-color: #dae0e5;
  background-color: #e2e6ea;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  border-color: #f8f9fa;
  background-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  border-color: #d3d9df;
  background-color: #dae0e5;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.btn-dark {
  color: #fff;
  border-color: #343a40;
  background-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  border-color: #1d2124;
  background-color: #23272b;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  border-color: #343a40;
  background-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  border-color: #171a1d;
  background-color: #1d2124;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-outline-primary {
  color: #d2aa78;
  border-color: #d2aa78;
  background-color: transparent;
  background-image: none;
}

.btn-outline-primary:hover {
  color: #212529;
  border-color: #d2aa78;
  background-color: #d2aa78;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem rgba(210, 170, 120, .5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #d2aa78;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  border-color: #d2aa78;
  background-color: #d2aa78;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(210, 170, 120, .5);
}

.btn-outline-secondary {
  color: #828a91;
  border-color: #828a91;
  background-color: transparent;
  background-image: none;
}

.btn-outline-secondary:hover {
  color: #fff;
  border-color: #828a91;
  background-color: #828a91;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #828a91;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  border-color: #828a91;
  background-color: #828a91;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
  background-color: transparent;
  background-image: none;
}

.btn-outline-success:hover {
  color: #fff;
  border-color: #28a745;
  background-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  border-color: #28a745;
  background-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
  background-color: transparent;
  background-image: none;
}

.btn-outline-info:hover {
  color: #fff;
  border-color: #17a2b8;
  background-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  border-color: #17a2b8;
  background-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
  background-color: transparent;
  background-image: none;
}

.btn-outline-warning:hover {
  color: #212529;
  border-color: #ffc107;
  background-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  border-color: #ffc107;
  background-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
  background-color: transparent;
  background-image: none;
}

.btn-outline-danger:hover {
  color: #fff;
  border-color: #dc3545;
  background-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  border-color: #dc3545;
  background-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
  background-color: transparent;
  background-image: none;
}

.btn-outline-light:hover {
  color: #212529;
  border-color: #f8f9fa;
  background-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  border-color: #f8f9fa;
  background-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
  background-color: transparent;
  background-image: none;
}

.btn-outline-dark:hover {
  color: #fff;
  border-color: #343a40;
  background-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  border-color: #343a40;
  background-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-link {
  font-weight: 400;

  color: #d2aa78;
  background-color: transparent;
}

.btn-link:hover {
  text-decoration: underline;

  color: #be863f;
  border-color: transparent;
  background-color: transparent;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;

  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  pointer-events: none;

  color: #828a91;
}

.btn-lg,
.btn-group-lg > .btn {
  font-size: 1.0625rem;
  line-height: 1.5;

  padding: 1.25rem 1.75rem;

  border-radius: 0;
}

.btn-sm,
.btn-group-sm > .btn {
  font-size: .8125rem;
  line-height: 1.5;

  padding: .75rem 1.25rem;

  border-radius: 0;
}

.btn-block {
  display: block;

  width: 100%;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;

  overflow: hidden;

  height: 0;

  transition: height .35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;

  width: 0;
  height: 0;
  margin-left: .255em;

  content: '';
  vertical-align: .255em;

  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  font-size: .9375rem;

  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;

  display: none;
  float: left;

  min-width: 12.5rem;
  margin: .125rem 0 0;
  padding: 0 0;

  list-style: none;

  text-align: left;

  color: #212529;
  border: 1px solid rgba(0, 0, 0, .1);
  background-color: #fff;
  background-clip: padding-box;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;

  margin-top: 0;
  margin-bottom: .125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;

  width: 0;
  height: 0;
  margin-left: .255em;

  content: '';
  vertical-align: .255em;

  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;

  margin-top: 0;
  margin-left: .125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;

  width: 0;
  height: 0;
  margin-left: .255em;

  content: '';
  vertical-align: .255em;

  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;

  margin-top: 0;
  margin-right: .125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;

  width: 0;
  height: 0;
  margin-left: .255em;

  content: '';
  vertical-align: .255em;
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;

  width: 0;
  height: 0;
  margin-right: .255em;

  content: '';
  vertical-align: .255em;

  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  overflow: hidden;

  height: 0;
  margin: .5rem 0;

  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  font-weight: 400;

  display: block;
  clear: both;

  width: 100%;
  padding: .75rem 1.5rem;

  text-align: inherit;
  white-space: nowrap;

  color: #212529;
  border: 0;
  background-color: transparent;
}

.dropdown-item:hover,
.dropdown-item:focus {
  text-decoration: none;

  color: #212529;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;

  color: #d2aa78;
  background-color: transparent;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #828a91;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  font-size: .8125rem;

  display: block;

  margin-bottom: 0;
  padding: 0 1.5rem;

  white-space: nowrap;

  color: #828a91;
}

.dropdown-item-text {
  display: block;

  padding: .75rem 1.5rem;

  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;

  display: inline-flex;

  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;

  flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: flex;

  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: .9375rem;
  padding-left: .9375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-group-vertical {
  flex-direction: column;

  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;

  clip: rect(0, 0, 0, 0);

  pointer-events: none;
}

.input-group {
  position: relative;

  display: flex;

  width: 100%;

  flex-wrap: wrap;
  align-items: stretch;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;

  width: 1%;
  margin-bottom: 0;

  flex: 1 1 auto;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
  z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .custom-file {
  display: flex;

  align-items: center;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.20313rem;

  display: flex;

  margin-bottom: 0;
  padding: 1rem .75rem;

  text-align: center;
  white-space: nowrap;

  color: #828a91;
  border: 1px solid #dee2e6;
  background-color: #f8f9fa;

  align-items: center;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.custom-control {
  position: relative;

  display: block;

  min-height: 1.75rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;

  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;

  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #d2aa78;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fffefe;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #828a91;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;

  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: .375rem;
  left: -1.5rem;

  display: block;

  width: 1rem;
  height: 1rem;

  content: '';
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;

  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: .375rem;
  left: -1.5rem;

  display: block;

  width: 1rem;
  height: 1rem;

  content: '';

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d2aa78;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 8 8\'%3E%3Cpath fill=\'%23fff\' d=\'M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z\'/%3E%3C/svg%3E');
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #d2aa78;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 4\'%3E%3Cpath stroke=\'%23fff\' d=\'M0 2h4\'/%3E%3C/svg%3E');
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(210, 170, 120, .5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(210, 170, 120, .5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d2aa78;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'-4 -4 8 8\'%3E%3Ccircle r=\'3\' fill=\'%23fff\'/%3E%3C/svg%3E');
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(210, 170, 120, .5);
}

.custom-select {
  line-height: 1.20313rem;

  display: inline-block;

  width: 100%;
  height: calc(3.64063rem + 2px);
  padding: 1rem 1.75rem 1rem .75rem;

  vertical-align: middle;

  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 0;
  background: #f8f9fa url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 4 5\'%3E%3Cpath fill=\'%23343a40\' d=\'M2 0L0 2h4zm0 5L0 3h4z\'/%3E%3C/svg%3E') no-repeat right .75rem center;
  background-size: 8px 10px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #d2aa78;
  outline: 0;
  box-shadow: none;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #f8f9fa;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: .75rem;

  background-image: none;
}

.custom-select:disabled {
  color: #828a91;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  font-size: 75%;

  height: calc(2.71875rem + 2px);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.custom-select-lg {
  font-size: 125%;

  height: calc(4.09375rem + 2px);
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.custom-file {
  position: relative;

  display: inline-block;

  width: 100%;
  height: calc(3.64063rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;

  width: 100%;
  height: calc(3.64063rem + 2px);
  margin: 0;

  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #d2aa78;
  box-shadow: none;
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #d2aa78;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-file-label {
  line-height: 1.75;

  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;

  height: calc(3.64063rem + 2px);
  padding: 1rem 1.5rem;

  color: #495057;
  border: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.custom-file-label::after {
  line-height: 1.75;

  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  bottom: 0;

  display: block;

  height: 3.64063rem;
  padding: 1rem 1.5rem;

  content: 'Browse';

  color: #495057;
  border-left: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.custom-range {
  width: 100%;
  padding-left: 0;

  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;

  border: 0;
  background-color: #d2aa78;

  -webkit-appearance: none;
  appearance: none;
}

.custom-range::-webkit-slider-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #fffefe;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;

  cursor: pointer;

  color: transparent;
  border-color: transparent;
  background-color: #dee2e6;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;

  border: 0;
  background-color: #d2aa78;

  -moz-appearance: none;
  appearance: none;
}

.custom-range::-moz-range-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-moz-range-thumb:active {
  background-color: #fffefe;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;

  cursor: pointer;

  color: transparent;
  border-color: transparent;
  background-color: #dee2e6;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;

  border: 0;
  background-color: #d2aa78;

  appearance: none;
}

.custom-range::-ms-thumb:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, none;
}

.custom-range::-ms-thumb:active {
  background-color: #fffefe;
}

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;

  cursor: pointer;

  color: transparent;
  border-width: .5rem;
  border-color: transparent;
  background-color: transparent;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;

  background-color: #dee2e6;
}

.nav {
  display: flex;

  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  flex-wrap: wrap;
}

.nav-link {
  display: block;

  padding: .5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #828a91;
}

.nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: rgba(0, 0, 0, .1);
}

.nav-tabs .nav-link.disabled {
  color: #828a91;
  border-color: transparent;
  background-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  border-color: #dee2e6 #dee2e6 #fff;
  background-color: #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: white;
  background-color: #d2aa78;
}

.nav-fill .nav-item {
  text-align: center;

  flex: 1 1 auto;
}

.nav-justified .nav-item {
  text-align: center;

  flex-basis: 0;
  flex-grow: 1;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;

  display: flex;

  padding: 1.25rem 1rem;

  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar > .container,
.navbar > .container-fluid {
  display: flex;

  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  font-size: 1.0625rem;
  line-height: inherit;

  display: inline-block;

  margin-right: 1rem;
  padding-top: .39063rem;
  padding-bottom: .39063rem;

  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;

  margin-bottom: 0;
  padding-left: 0;

  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;

  float: none;
}

.navbar-text {
  display: inline-block;

  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  font-size: 1.0625rem;
  line-height: 1;

  padding: .25rem .75rem;

  border: 1px solid transparent;
  background-color: transparent;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;

  width: 1.5em;
  height: 1.5em;

  content: '';
  vertical-align: middle;

  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;

    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;

  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: #d2aa78;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: #d2aa78;
}

.navbar-light .navbar-nav .nav-link {
  color: #212529;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #d2aa78;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, .3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #d2aa78;
}

.navbar-light .navbar-toggler {
  color: #212529;
  border-color: transparent;
}

.navbar-light .navbar-text {
  color: #212529;
}

.navbar-light .navbar-text a {
  color: #d2aa78;
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #d2aa78;
}

.navbar-dark .navbar-brand {
  color: #d2aa78;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #d2aa78;
}

.navbar-dark .navbar-nav .nav-link {
  color: white;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #d2aa78;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #d2aa78;
}

.navbar-dark .navbar-toggler {
  color: white;
  border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox=\'0 0 30 30\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath stroke=\'white\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-miterlimit=\'10\' d=\'M4 7h22M4 15h22M4 23h22\'/%3E%3C/svg%3E');
}

.navbar-dark .navbar-text {
  color: white;
}

.navbar-dark .navbar-text a {
  color: #d2aa78;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #d2aa78;
}

.card {
  position: relative;

  display: flex;
  flex-direction: column;

  min-width: 0;

  word-wrap: break-word;

  border: 1px solid rgba(0, 0, 0, .1);
  background-color: transparent;
  background-clip: border-box;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card-body {
  padding: 2.5rem 2rem;

  flex: 1 1 auto;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 2.5rem 2rem;
}

.card-header {
  margin-bottom: 0;
  padding: 1rem 2.5rem 2rem;

  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .03);
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 1rem 2.5rem 2rem;

  border-top: 1px solid rgba(0, 0, 0, .1);
  background-color: rgba(0, 0, 0, .03);
}

.card-header-tabs {
  margin-right: -2.5rem 2rem/2;
  margin-bottom: -1rem;
  margin-left: -2.5rem 2rem/2;

  border-bottom: 0;
}

.card-header-pills {
  margin-right: -2.5rem 2rem/2;
  margin-left: -2.5rem 2rem/2;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 2.5rem 2rem;
}

.card-img {
  width: 100%;
}

.card-img-top {
  width: 100%;
}

.card-img-bottom {
  width: 100%;
}

.card-deck {
  display: flex;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .card-deck {
    margin-right: -1.5rem;
    margin-left: -1.5rem;

    flex-flow: row wrap;
  }
  .card-deck .card {
    display: flex;
    flex-direction: column;

    margin-right: 1.5rem;
    margin-bottom: 0;
    margin-left: 1.5rem;

    flex: 1 0;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    margin-bottom: 0;

    flex: 1 0;
  }
  .card-group > .card + .card {
    margin-left: 0;

    border-left: 0;
  }
}

.card-columns .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;

    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: flex;

  margin-bottom: 0;
  padding: 1.5rem 0;

  list-style: none;

  background-color: #343a40;

  flex-wrap: wrap;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;

  padding-right: .5rem;

  content: '';

  color: rgba(255, 255, 255, .5);
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: rgba(255, 255, 255, .5);
}

.pagination {
  display: flex;

  padding-left: 0;

  list-style: none;
}

.page-link {
  line-height: 1.25;

  position: relative;

  display: block;

  margin-left: -1px;
  padding: 1rem 1.5rem;

  color: #d2aa78;
  border: 1px solid rgba(0, 0, 0, .1);
  background-color: #fff;
}

.page-link:hover {
  z-index: 2;

  text-decoration: none;

  color: #be863f;
  border-color: #dee2e6;
  background-color: #f8f9fa;
}

.page-link:focus {
  z-index: 2;

  outline: 0;
  box-shadow: none;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item.active .page-link {
  z-index: 1;

  color: #fff;
  border-color: #d2aa78;
  background-color: #d2aa78;
}

.page-item.disabled .page-link {
  cursor: auto;
  pointer-events: none;

  color: #828a91;
  border-color: #dee2e6;
  background-color: #fff;
}

.pagination-lg .page-link {
  font-size: 1.0625rem;
  line-height: 1.5;

  padding: 1.25rem 1.75rem;
}

.pagination-sm .page-link {
  font-size: .8125rem;
  line-height: 1.5;

  padding: .75rem 1.25rem;
}

.badge {
  font-size: 75%;
  font-weight: 500;
  line-height: 1;

  display: inline-block;

  padding: .25em .4em;

  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
}

.badge-primary {
  color: #212529;
  background-color: #d2aa78;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  text-decoration: none;

  color: #212529;
  background-color: #c59252;
}

.badge-secondary {
  color: #fff;
  background-color: #828a91;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  text-decoration: none;

  color: #fff;
  background-color: #697077;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  text-decoration: none;

  color: #fff;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  text-decoration: none;

  color: #fff;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  text-decoration: none;

  color: #212529;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  text-decoration: none;

  color: #fff;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
  text-decoration: none;

  color: #212529;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  text-decoration: none;

  color: #fff;
  background-color: #1d2124;
}

.jumbotron {
  margin-bottom: 2rem;
  padding: 2rem 1rem;

  background-color: #e9ecef;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
}

.alert {
  position: relative;

  margin-bottom: 1rem;
  padding: 1.5rem 1.5rem;

  border: 1px solid transparent;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 4.40625rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;

  padding: 1.5rem 1.5rem;

  color: inherit;
}

.alert-primary {
  color: #6d583e;
  border-color: #d2aa78;
  background-color: #f6eee4;
}

.alert-primary hr {
  border-top-color: #cc9e65;
}

.alert-primary .alert-link {
  color: #4c3e2c;
}

.alert-secondary {
  color: #44484b;
  border-color: #828a91;
  background-color: #e6e8e9;
}

.alert-secondary hr {
  border-top-color: #757d85;
}

.alert-secondary .alert-link {
  color: #2c2e30;
}

.alert-success {
  color: #155724;
  border-color: #28a745;
  background-color: #d4edda;
}

.alert-success hr {
  border-top-color: #23923d;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  border-color: #17a2b8;
  background-color: #d1ecf1;
}

.alert-info hr {
  border-top-color: #148ea1;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  border-color: #ffc107;
  background-color: #fff3cd;
}

.alert-warning hr {
  border-top-color: #edb100;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  border-color: #dc3545;
  background-color: #f8d7da;
}

.alert-danger hr {
  border-top-color: #d32535;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  border-color: #f8f9fa;
  background-color: #fefefe;
}

.alert-light hr {
  border-top-color: #e9ecef;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  border-color: #343a40;
  background-color: #d6d8d9;
}

.alert-dark hr {
  border-top-color: #292d32;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  font-size: .70313rem;

  display: flex;
  overflow: hidden;

  height: 1rem;

  background-color: #e9ecef;
}

.progress-bar {
  display: flex;
  flex-direction: column;

  transition: width .6s ease;
  text-align: center;
  white-space: nowrap;

  color: #fff;
  background-color: #d2aa78;

  justify-content: center;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: flex;

  align-items: flex-start;
}

.media-body {
  flex: 1 1;
}

.list-group {
  display: flex;
  flex-direction: column;

  margin-bottom: 0;
  padding-left: 0;
}

.list-group-item-action {
  width: 100%;

  text-align: inherit;

  color: #495057;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  text-decoration: none;

  color: #495057;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;

  display: block;

  margin-bottom: -1px;
  padding: .75rem 1.25rem;

  border: 1px solid rgba(0, 0, 0, .125);
  background-color: #fff;
}

.list-group-item:last-child {
  margin-bottom: 0;
}

.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;

  text-decoration: none;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #828a91;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;

  color: #fff;
  border-color: #d2aa78;
  background-color: #d2aa78;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #6d583e;
  background-color: #f2e7d9;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #6d583e;
  background-color: #ecdbc6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  border-color: #6d583e;
  background-color: #6d583e;
}

.list-group-item-secondary {
  color: #44484b;
  background-color: #dcdee0;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #44484b;
  background-color: #ced1d4;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  border-color: #44484b;
  background-color: #44484b;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  border-color: #155724;
  background-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  border-color: #0c5460;
  background-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  border-color: #856404;
  background-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  border-color: #721c24;
  background-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  border-color: #818182;
  background-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  border-color: #1b1e21;
  background-color: #1b1e21;
}

.close {
  font-size: 1.40625rem;
  font-weight: 500;
  line-height: 1;

  float: right;

  opacity: .5;
  color: #000;
  text-shadow: 0 1px 0 #fff;
}

.close:hover,
.close:focus {
  text-decoration: none;

  opacity: .75;
  color: #000;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;

  border: 0;
  background-color: transparent;

  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;
  overflow: hidden;

  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;

  width: auto;
  margin: .5rem;

  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: flex;

  min-height: calc(100% - (.5rem * 2));

  align-items: center;
}

.modal-content {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  pointer-events: auto;

  border: 1px solid rgba(0, 0, 0, .2);
  outline: 0;
  background-color: #fff;
  background-clip: padding-box;
}

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  display: flex;

  padding: 1rem;

  border-bottom: 0 solid #e9ecef;

  align-items: flex-start;
  justify-content: space-between;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  line-height: 1.75;

  margin-bottom: 0;
}

.modal-body {
  position: relative;

  padding: 1rem;

  flex: 1 1 auto;
}

.modal-footer {
  display: flex;

  padding: 1rem;

  border-top: 0 solid #e9ecef;

  align-items: center;
  justify-content: flex-end;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;

  overflow: scroll;

  width: 50px;
  height: 50px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  font-family: 'Objective', sans-serif;
  font-size: .8125rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.75;

  position: absolute;
  z-index: 1070;

  display: block;

  margin: 0;

  text-align: left;
  text-align: start;
  white-space: normal;
  text-decoration: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  word-wrap: break-word;
  word-break: normal;

  opacity: 0;
  text-shadow: none;

  line-break: auto;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  position: absolute;

  display: block;

  width: .8rem;
  height: .4rem;
}

.tooltip .arrow::before {
  position: absolute;

  content: '';

  border-style: solid;
  border-color: transparent;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;

  border-width: .4rem .4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;

  width: .4rem;
  height: .8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;

  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;

  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;

  width: .4rem;
  height: .8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;

  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;

  text-align: center;

  color: #fff;
  background-color: #000;
}

.popover {
  font-family: 'Objective', sans-serif;
  font-size: .8125rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.75;

  position: absolute;
  z-index: 1060;
  top: 0;
  left: 0;

  display: block;

  max-width: 276px;

  text-align: left;
  text-align: start;
  white-space: normal;
  text-decoration: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  word-wrap: break-word;
  word-break: normal;

  border: 1px solid rgba(0, 0, 0, .2);
  background-color: #fff;
  background-clip: padding-box;
  text-shadow: none;

  line-break: auto;
}

.popover .arrow {
  position: absolute;

  display: block;

  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;

  display: block;

  content: '';

  border-style: solid;
  border-color: transparent;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: .5rem;
}

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^='top'] .arrow {
  bottom: calc((.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
  border-width: .5rem .5rem 0;
}

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before {
  bottom: 0;

  border-top-color: rgba(0, 0, 0, .25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
  bottom: 1px;

  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: .5rem;
}

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^='right'] .arrow {
  left: calc((.5rem + 1px) * -1);

  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^='right'] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
  border-width: .5rem .5rem .5rem 0;
}

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^='right'] .arrow::before {
  left: 0;

  border-right-color: rgba(0, 0, 0, .25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
  left: 1px;

  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: .5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^='bottom'] .arrow {
  top: calc((.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  border-width: 0 .5rem .5rem .5rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before {
  top: 0;

  border-bottom-color: rgba(0, 0, 0, .25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  top: 1px;

  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;

  display: block;

  width: 1rem;
  margin-left: -.5rem;

  content: '';

  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: .5rem;
}

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^='left'] .arrow {
  right: calc((.5rem + 1px) * -1);

  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^='left'] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after {
  border-width: .5rem 0 .5rem .5rem;
}

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^='left'] .arrow::before {
  right: 0;

  border-left-color: rgba(0, 0, 0, .25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after {
  right: 1px;

  border-left-color: #fff;
}

.popover-header {
  font-size: .9375rem;

  margin-bottom: 0;
  padding: .5rem .75rem;

  color: #212529;
  border-bottom: 1px solid #ebebeb;
  background-color: #f7f7f7;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: .5rem .75rem;

  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;

  overflow: hidden;

  width: 100%;
}

.carousel-item {
  position: relative;

  display: none;

  width: 100%;

  transition: transform .6s ease;

  align-items: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  transition-duration: .6s;
  transition-property: opacity;

  opacity: 0;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;

  display: flex;

  width: 15%;

  text-align: center;

  opacity: .5;
  color: #fff;

  align-items: center;
  justify-content: center;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  text-decoration: none;

  opacity: .9;
  color: #fff;
  outline: 0;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;

  width: 20px;
  height: 20px;

  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' viewBox=\'0 0 8 8\'%3E%3Cpath d=\'M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z\'/%3E%3C/svg%3E');
}

.carousel-control-next-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'%23fff\' viewBox=\'0 0 8 8\'%3E%3Cpath d=\'M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z\'/%3E%3C/svg%3E');
}

.carousel-indicators {
  position: absolute;
  z-index: 15;
  right: 0;
  bottom: 10px;
  left: 0;

  display: flex;

  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;

  list-style: none;

  justify-content: center;
}

.carousel-indicators li {
  position: relative;

  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;

  cursor: pointer;
  text-indent: -999px;

  background-color: rgba(255, 255, 255, .5);

  flex: 0 1 auto;
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;

  display: inline-block;

  width: 100%;
  height: 10px;

  content: '';
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;

  display: inline-block;

  width: 100%;
  height: 10px;

  content: '';
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  z-index: 10;
  right: 15%;
  bottom: 20px;
  left: 15%;

  padding-top: 20px;
  padding-bottom: 20px;

  text-align: center;

  color: #fff;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #d2aa78 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #c59252 !important;
}

.bg-secondary {
  background-color: #828a91 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #697077 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, .1) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, .1) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, .1) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, .1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #d2aa78 !important;
}

.border-secondary {
  border-color: #828a91 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;

  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;

  display: block;
  overflow: hidden;

  width: 100%;
  padding: 0;
}

.embed-responsive::before {
  display: block;

  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  z-index: 1030;
  top: 0;
  right: 0;
  left: 0;
}

.fixed-bottom {
  position: fixed;
  z-index: 1030;
  right: 0;
  bottom: 0;
  left: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    z-index: 1020;
    top: 0;
  }
}

.sr-only {
  position: absolute;

  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  width: 1px;
  height: 1px;
  padding: 0;

  white-space: nowrap;

  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;

  overflow: visible;
  clip: auto;

  width: auto;
  height: auto;

  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.shadow {
  box-shadow: 0 25px 100px -5px rgba(0, 0, 0, .15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1,
.my-1 {
  margin-top: .25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: .25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: .25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2,
.my-2 {
  margin-top: .5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: .5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: .5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m--1 {
  margin: -.25rem !important;
}

.mt--1,
.my--1 {
  margin-top: -.25rem !important;
}

.mr--1,
.mx--1 {
  margin-right: -.25rem !important;
}

.mb--1,
.my--1 {
  margin-bottom: -.25rem !important;
}

.ml--1,
.mx--1 {
  margin-left: -.25rem !important;
}

.m--2 {
  margin: -.5rem !important;
}

.mt--2,
.my--2 {
  margin-top: -.5rem !important;
}

.mr--2,
.mx--2 {
  margin-right: -.5rem !important;
}

.mb--2,
.my--2 {
  margin-bottom: -.5rem !important;
}

.ml--2,
.mx--2 {
  margin-left: -.5rem !important;
}

.m--3 {
  margin: 1rem !important;
}

.mt--3,
.my--3 {
  margin-top: 1rem !important;
}

.mr--3,
.mx--3 {
  margin-right: 1rem !important;
}

.mb--3,
.my--3 {
  margin-bottom: 1rem !important;
}

.ml--3,
.mx--3 {
  margin-left: 1rem !important;
}

.m--4 {
  margin: -1.5rem !important;
}

.mt--4,
.my--4 {
  margin-top: -1.5rem !important;
}

.mr--4,
.mx--4 {
  margin-right: -1.5rem !important;
}

.mb--4,
.my--4 {
  margin-bottom: -1.5rem !important;
}

.ml--4,
.mx--4 {
  margin-left: -1.5rem !important;
}

.m--5 {
  margin: -3rem !important;
}

.mt--5,
.my--5 {
  margin-top: -3rem !important;
}

.mr--5,
.mx--5 {
  margin-right: -3rem !important;
}

.mb--5,
.my--5 {
  margin-bottom: -3rem !important;
}

.ml--5,
.mx--5 {
  margin-left: -3rem !important;
}

.m--6 {
  margin: -4.5rem !important;
}

.mt--6,
.my--6 {
  margin-top: -4.5rem !important;
}

.mr--6,
.mx--6 {
  margin-right: -4.5rem !important;
}

.mb--6,
.my--6 {
  margin-bottom: -4.5rem !important;
}

.ml--6,
.mx--6 {
  margin-left: -4.5rem !important;
}

.m--7 {
  margin: -6rem !important;
}

.mt--7,
.my--7 {
  margin-top: -6rem !important;
}

.mr--7,
.mx--7 {
  margin-right: -6rem !important;
}

.mb--7,
.my--7 {
  margin-bottom: -6rem !important;
}

.ml--7,
.mx--7 {
  margin-left: -6rem !important;
}

.m--8 {
  margin: -7.5rem !important;
}

.mt--8,
.my--8 {
  margin-top: -7.5rem !important;
}

.mr--8,
.mx--8 {
  margin-right: -7.5rem !important;
}

.mb--8,
.my--8 {
  margin-bottom: -7.5rem !important;
}

.ml--8,
.mx--8 {
  margin-left: -7.5rem !important;
}

.m--9 {
  margin: -9rem !important;
}

.mt--9,
.my--9 {
  margin-top: -9rem !important;
}

.mr--9,
.mx--9 {
  margin-right: -9rem !important;
}

.mb--9,
.my--9 {
  margin-bottom: -9rem !important;
}

.ml--9,
.mx--9 {
  margin-left: -9rem !important;
}

.m--10 {
  margin: -9.5rem !important;
}

.mt--10,
.my--10 {
  margin-top: -9.5rem !important;
}

.mr--10,
.mx--10 {
  margin-right: -9.5rem !important;
}

.mb--10,
.my--10 {
  margin-bottom: -9.5rem !important;
}

.ml--10,
.mx--10 {
  margin-left: -9.5rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6,
.my-6 {
  margin-top: 4.5rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7,
.my-7 {
  margin-top: 6rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 6rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 6rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 6rem !important;
}

.m-8 {
  margin: 7.5rem !important;
}

.mt-8,
.my-8 {
  margin-top: 7.5rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 7.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 7.5rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 7.5rem !important;
}

.m-9 {
  margin: 9rem !important;
}

.mt-9,
.my-9 {
  margin-top: 9rem !important;
}

.mr-9,
.mx-9 {
  margin-right: 9rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 9rem !important;
}

.ml-9,
.mx-9 {
  margin-left: 9rem !important;
}

.m-10 {
  margin: 9.5rem !important;
}

.mt-10,
.my-10 {
  margin-top: 9.5rem !important;
}

.mr-10,
.mx-10 {
  margin-right: 9.5rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 9.5rem !important;
}

.ml-10,
.mx-10 {
  margin-left: 9.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1,
.py-1 {
  padding-top: .25rem !important;
}

.pr-1,
.px-1 {
  padding-right: .25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: .25rem !important;
}

.pl-1,
.px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2,
.py-2 {
  padding-top: .5rem !important;
}

.pr-2,
.px-2 {
  padding-right: .5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: .5rem !important;
}

.pl-2,
.px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p--1 {
  padding: -.25rem !important;
}

.pt--1,
.py--1 {
  padding-top: -.25rem !important;
}

.pr--1,
.px--1 {
  padding-right: -.25rem !important;
}

.pb--1,
.py--1 {
  padding-bottom: -.25rem !important;
}

.pl--1,
.px--1 {
  padding-left: -.25rem !important;
}

.p--2 {
  padding: -.5rem !important;
}

.pt--2,
.py--2 {
  padding-top: -.5rem !important;
}

.pr--2,
.px--2 {
  padding-right: -.5rem !important;
}

.pb--2,
.py--2 {
  padding-bottom: -.5rem !important;
}

.pl--2,
.px--2 {
  padding-left: -.5rem !important;
}

.p--3 {
  padding: 1rem !important;
}

.pt--3,
.py--3 {
  padding-top: 1rem !important;
}

.pr--3,
.px--3 {
  padding-right: 1rem !important;
}

.pb--3,
.py--3 {
  padding-bottom: 1rem !important;
}

.pl--3,
.px--3 {
  padding-left: 1rem !important;
}

.p--4 {
  padding: -1.5rem !important;
}

.pt--4,
.py--4 {
  padding-top: -1.5rem !important;
}

.pr--4,
.px--4 {
  padding-right: -1.5rem !important;
}

.pb--4,
.py--4 {
  padding-bottom: -1.5rem !important;
}

.pl--4,
.px--4 {
  padding-left: -1.5rem !important;
}

.p--5 {
  padding: -3rem !important;
}

.pt--5,
.py--5 {
  padding-top: -3rem !important;
}

.pr--5,
.px--5 {
  padding-right: -3rem !important;
}

.pb--5,
.py--5 {
  padding-bottom: -3rem !important;
}

.pl--5,
.px--5 {
  padding-left: -3rem !important;
}

.p--6 {
  padding: -4.5rem !important;
}

.pt--6,
.py--6 {
  padding-top: -4.5rem !important;
}

.pr--6,
.px--6 {
  padding-right: -4.5rem !important;
}

.pb--6,
.py--6 {
  padding-bottom: -4.5rem !important;
}

.pl--6,
.px--6 {
  padding-left: -4.5rem !important;
}

.p--7 {
  padding: -6rem !important;
}

.pt--7,
.py--7 {
  padding-top: -6rem !important;
}

.pr--7,
.px--7 {
  padding-right: -6rem !important;
}

.pb--7,
.py--7 {
  padding-bottom: -6rem !important;
}

.pl--7,
.px--7 {
  padding-left: -6rem !important;
}

.p--8 {
  padding: -7.5rem !important;
}

.pt--8,
.py--8 {
  padding-top: -7.5rem !important;
}

.pr--8,
.px--8 {
  padding-right: -7.5rem !important;
}

.pb--8,
.py--8 {
  padding-bottom: -7.5rem !important;
}

.pl--8,
.px--8 {
  padding-left: -7.5rem !important;
}

.p--9 {
  padding: -9rem !important;
}

.pt--9,
.py--9 {
  padding-top: -9rem !important;
}

.pr--9,
.px--9 {
  padding-right: -9rem !important;
}

.pb--9,
.py--9 {
  padding-bottom: -9rem !important;
}

.pl--9,
.px--9 {
  padding-left: -9rem !important;
}

.p--10 {
  padding: -9.5rem !important;
}

.pt--10,
.py--10 {
  padding-top: -9.5rem !important;
}

.pr--10,
.px--10 {
  padding-right: -9.5rem !important;
}

.pb--10,
.py--10 {
  padding-bottom: -9.5rem !important;
}

.pl--10,
.px--10 {
  padding-left: -9.5rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6,
.py-6 {
  padding-top: 4.5rem !important;
}

.pr-6,
.px-6 {
  padding-right: 4.5rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6,
.px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7,
.py-7 {
  padding-top: 6rem !important;
}

.pr-7,
.px-7 {
  padding-right: 6rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 6rem !important;
}

.pl-7,
.px-7 {
  padding-left: 6rem !important;
}

.p-8 {
  padding: 7.5rem !important;
}

.pt-8,
.py-8 {
  padding-top: 7.5rem !important;
}

.pr-8,
.px-8 {
  padding-right: 7.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 7.5rem !important;
}

.pl-8,
.px-8 {
  padding-left: 7.5rem !important;
}

.p-9 {
  padding: 9rem !important;
}

.pt-9,
.py-9 {
  padding-top: 9rem !important;
}

.pr-9,
.px-9 {
  padding-right: 9rem !important;
}

.pb-9,
.py-9 {
  padding-bottom: 9rem !important;
}

.pl-9,
.px-9 {
  padding-left: 9rem !important;
}

.p-10 {
  padding: 9.5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 9.5rem !important;
}

.pr-10,
.px-10 {
  padding-right: 9.5rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 9.5rem !important;
}

.pl-10,
.px-10 {
  padding-left: 9.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: .25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: .25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: .25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: .25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: .25rem !important;
  }
  .m-sm-2 {
    margin: .5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: .5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: .5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: .5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: .5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm--1 {
    margin: -.25rem !important;
  }
  .mt-sm--1,
  .my-sm--1 {
    margin-top: -.25rem !important;
  }
  .mr-sm--1,
  .mx-sm--1 {
    margin-right: -.25rem !important;
  }
  .mb-sm--1,
  .my-sm--1 {
    margin-bottom: -.25rem !important;
  }
  .ml-sm--1,
  .mx-sm--1 {
    margin-left: -.25rem !important;
  }
  .m-sm--2 {
    margin: -.5rem !important;
  }
  .mt-sm--2,
  .my-sm--2 {
    margin-top: -.5rem !important;
  }
  .mr-sm--2,
  .mx-sm--2 {
    margin-right: -.5rem !important;
  }
  .mb-sm--2,
  .my-sm--2 {
    margin-bottom: -.5rem !important;
  }
  .ml-sm--2,
  .mx-sm--2 {
    margin-left: -.5rem !important;
  }
  .m-sm--3 {
    margin: 1rem !important;
  }
  .mt-sm--3,
  .my-sm--3 {
    margin-top: 1rem !important;
  }
  .mr-sm--3,
  .mx-sm--3 {
    margin-right: 1rem !important;
  }
  .mb-sm--3,
  .my-sm--3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm--3,
  .mx-sm--3 {
    margin-left: 1rem !important;
  }
  .m-sm--4 {
    margin: -1.5rem !important;
  }
  .mt-sm--4,
  .my-sm--4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm--4,
  .mx-sm--4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm--4,
  .my-sm--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm--4,
  .mx-sm--4 {
    margin-left: -1.5rem !important;
  }
  .m-sm--5 {
    margin: -3rem !important;
  }
  .mt-sm--5,
  .my-sm--5 {
    margin-top: -3rem !important;
  }
  .mr-sm--5,
  .mx-sm--5 {
    margin-right: -3rem !important;
  }
  .mb-sm--5,
  .my-sm--5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm--5,
  .mx-sm--5 {
    margin-left: -3rem !important;
  }
  .m-sm--6 {
    margin: -4.5rem !important;
  }
  .mt-sm--6,
  .my-sm--6 {
    margin-top: -4.5rem !important;
  }
  .mr-sm--6,
  .mx-sm--6 {
    margin-right: -4.5rem !important;
  }
  .mb-sm--6,
  .my-sm--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-sm--6,
  .mx-sm--6 {
    margin-left: -4.5rem !important;
  }
  .m-sm--7 {
    margin: -6rem !important;
  }
  .mt-sm--7,
  .my-sm--7 {
    margin-top: -6rem !important;
  }
  .mr-sm--7,
  .mx-sm--7 {
    margin-right: -6rem !important;
  }
  .mb-sm--7,
  .my-sm--7 {
    margin-bottom: -6rem !important;
  }
  .ml-sm--7,
  .mx-sm--7 {
    margin-left: -6rem !important;
  }
  .m-sm--8 {
    margin: -7.5rem !important;
  }
  .mt-sm--8,
  .my-sm--8 {
    margin-top: -7.5rem !important;
  }
  .mr-sm--8,
  .mx-sm--8 {
    margin-right: -7.5rem !important;
  }
  .mb-sm--8,
  .my-sm--8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-sm--8,
  .mx-sm--8 {
    margin-left: -7.5rem !important;
  }
  .m-sm--9 {
    margin: -9rem !important;
  }
  .mt-sm--9,
  .my-sm--9 {
    margin-top: -9rem !important;
  }
  .mr-sm--9,
  .mx-sm--9 {
    margin-right: -9rem !important;
  }
  .mb-sm--9,
  .my-sm--9 {
    margin-bottom: -9rem !important;
  }
  .ml-sm--9,
  .mx-sm--9 {
    margin-left: -9rem !important;
  }
  .m-sm--10 {
    margin: -9.5rem !important;
  }
  .mt-sm--10,
  .my-sm--10 {
    margin-top: -9.5rem !important;
  }
  .mr-sm--10,
  .mx-sm--10 {
    margin-right: -9.5rem !important;
  }
  .mb-sm--10,
  .my-sm--10 {
    margin-bottom: -9.5rem !important;
  }
  .ml-sm--10,
  .mx-sm--10 {
    margin-left: -9.5rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 4.5rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 6rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 6rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 6rem !important;
  }
  .m-sm-8 {
    margin: 7.5rem !important;
  }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 7.5rem !important;
  }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 7.5rem !important;
  }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 7.5rem !important;
  }
  .m-sm-9 {
    margin: 9rem !important;
  }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important;
  }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important;
  }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important;
  }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important;
  }
  .m-sm-10 {
    margin: 9.5rem !important;
  }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 9.5rem !important;
  }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 9.5rem !important;
  }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 9.5rem !important;
  }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 9.5rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: .25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: .25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: .25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: .25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: .25rem !important;
  }
  .p-sm-2 {
    padding: .5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: .5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: .5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: .5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: .5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm--1 {
    padding: -.25rem !important;
  }
  .pt-sm--1,
  .py-sm--1 {
    padding-top: -.25rem !important;
  }
  .pr-sm--1,
  .px-sm--1 {
    padding-right: -.25rem !important;
  }
  .pb-sm--1,
  .py-sm--1 {
    padding-bottom: -.25rem !important;
  }
  .pl-sm--1,
  .px-sm--1 {
    padding-left: -.25rem !important;
  }
  .p-sm--2 {
    padding: -.5rem !important;
  }
  .pt-sm--2,
  .py-sm--2 {
    padding-top: -.5rem !important;
  }
  .pr-sm--2,
  .px-sm--2 {
    padding-right: -.5rem !important;
  }
  .pb-sm--2,
  .py-sm--2 {
    padding-bottom: -.5rem !important;
  }
  .pl-sm--2,
  .px-sm--2 {
    padding-left: -.5rem !important;
  }
  .p-sm--3 {
    padding: 1rem !important;
  }
  .pt-sm--3,
  .py-sm--3 {
    padding-top: 1rem !important;
  }
  .pr-sm--3,
  .px-sm--3 {
    padding-right: 1rem !important;
  }
  .pb-sm--3,
  .py-sm--3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm--3,
  .px-sm--3 {
    padding-left: 1rem !important;
  }
  .p-sm--4 {
    padding: -1.5rem !important;
  }
  .pt-sm--4,
  .py-sm--4 {
    padding-top: -1.5rem !important;
  }
  .pr-sm--4,
  .px-sm--4 {
    padding-right: -1.5rem !important;
  }
  .pb-sm--4,
  .py-sm--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-sm--4,
  .px-sm--4 {
    padding-left: -1.5rem !important;
  }
  .p-sm--5 {
    padding: -3rem !important;
  }
  .pt-sm--5,
  .py-sm--5 {
    padding-top: -3rem !important;
  }
  .pr-sm--5,
  .px-sm--5 {
    padding-right: -3rem !important;
  }
  .pb-sm--5,
  .py-sm--5 {
    padding-bottom: -3rem !important;
  }
  .pl-sm--5,
  .px-sm--5 {
    padding-left: -3rem !important;
  }
  .p-sm--6 {
    padding: -4.5rem !important;
  }
  .pt-sm--6,
  .py-sm--6 {
    padding-top: -4.5rem !important;
  }
  .pr-sm--6,
  .px-sm--6 {
    padding-right: -4.5rem !important;
  }
  .pb-sm--6,
  .py-sm--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-sm--6,
  .px-sm--6 {
    padding-left: -4.5rem !important;
  }
  .p-sm--7 {
    padding: -6rem !important;
  }
  .pt-sm--7,
  .py-sm--7 {
    padding-top: -6rem !important;
  }
  .pr-sm--7,
  .px-sm--7 {
    padding-right: -6rem !important;
  }
  .pb-sm--7,
  .py-sm--7 {
    padding-bottom: -6rem !important;
  }
  .pl-sm--7,
  .px-sm--7 {
    padding-left: -6rem !important;
  }
  .p-sm--8 {
    padding: -7.5rem !important;
  }
  .pt-sm--8,
  .py-sm--8 {
    padding-top: -7.5rem !important;
  }
  .pr-sm--8,
  .px-sm--8 {
    padding-right: -7.5rem !important;
  }
  .pb-sm--8,
  .py-sm--8 {
    padding-bottom: -7.5rem !important;
  }
  .pl-sm--8,
  .px-sm--8 {
    padding-left: -7.5rem !important;
  }
  .p-sm--9 {
    padding: -9rem !important;
  }
  .pt-sm--9,
  .py-sm--9 {
    padding-top: -9rem !important;
  }
  .pr-sm--9,
  .px-sm--9 {
    padding-right: -9rem !important;
  }
  .pb-sm--9,
  .py-sm--9 {
    padding-bottom: -9rem !important;
  }
  .pl-sm--9,
  .px-sm--9 {
    padding-left: -9rem !important;
  }
  .p-sm--10 {
    padding: -9.5rem !important;
  }
  .pt-sm--10,
  .py-sm--10 {
    padding-top: -9.5rem !important;
  }
  .pr-sm--10,
  .px-sm--10 {
    padding-right: -9.5rem !important;
  }
  .pb-sm--10,
  .py-sm--10 {
    padding-bottom: -9.5rem !important;
  }
  .pl-sm--10,
  .px-sm--10 {
    padding-left: -9.5rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 6rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 6rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 6rem !important;
  }
  .p-sm-8 {
    padding: 7.5rem !important;
  }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 7.5rem !important;
  }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 7.5rem !important;
  }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 7.5rem !important;
  }
  .p-sm-9 {
    padding: 9rem !important;
  }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important;
  }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important;
  }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important;
  }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important;
  }
  .p-sm-10 {
    padding: 9.5rem !important;
  }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 9.5rem !important;
  }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 9.5rem !important;
  }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 9.5rem !important;
  }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 9.5rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: .25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: .25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: .25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: .25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: .25rem !important;
  }
  .m-md-2 {
    margin: .5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: .5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: .5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: .5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: .5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md--1 {
    margin: -.25rem !important;
  }
  .mt-md--1,
  .my-md--1 {
    margin-top: -.25rem !important;
  }
  .mr-md--1,
  .mx-md--1 {
    margin-right: -.25rem !important;
  }
  .mb-md--1,
  .my-md--1 {
    margin-bottom: -.25rem !important;
  }
  .ml-md--1,
  .mx-md--1 {
    margin-left: -.25rem !important;
  }
  .m-md--2 {
    margin: -.5rem !important;
  }
  .mt-md--2,
  .my-md--2 {
    margin-top: -.5rem !important;
  }
  .mr-md--2,
  .mx-md--2 {
    margin-right: -.5rem !important;
  }
  .mb-md--2,
  .my-md--2 {
    margin-bottom: -.5rem !important;
  }
  .ml-md--2,
  .mx-md--2 {
    margin-left: -.5rem !important;
  }
  .m-md--3 {
    margin: 1rem !important;
  }
  .mt-md--3,
  .my-md--3 {
    margin-top: 1rem !important;
  }
  .mr-md--3,
  .mx-md--3 {
    margin-right: 1rem !important;
  }
  .mb-md--3,
  .my-md--3 {
    margin-bottom: 1rem !important;
  }
  .ml-md--3,
  .mx-md--3 {
    margin-left: 1rem !important;
  }
  .m-md--4 {
    margin: -1.5rem !important;
  }
  .mt-md--4,
  .my-md--4 {
    margin-top: -1.5rem !important;
  }
  .mr-md--4,
  .mx-md--4 {
    margin-right: -1.5rem !important;
  }
  .mb-md--4,
  .my-md--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md--4,
  .mx-md--4 {
    margin-left: -1.5rem !important;
  }
  .m-md--5 {
    margin: -3rem !important;
  }
  .mt-md--5,
  .my-md--5 {
    margin-top: -3rem !important;
  }
  .mr-md--5,
  .mx-md--5 {
    margin-right: -3rem !important;
  }
  .mb-md--5,
  .my-md--5 {
    margin-bottom: -3rem !important;
  }
  .ml-md--5,
  .mx-md--5 {
    margin-left: -3rem !important;
  }
  .m-md--6 {
    margin: -4.5rem !important;
  }
  .mt-md--6,
  .my-md--6 {
    margin-top: -4.5rem !important;
  }
  .mr-md--6,
  .mx-md--6 {
    margin-right: -4.5rem !important;
  }
  .mb-md--6,
  .my-md--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-md--6,
  .mx-md--6 {
    margin-left: -4.5rem !important;
  }
  .m-md--7 {
    margin: -6rem !important;
  }
  .mt-md--7,
  .my-md--7 {
    margin-top: -6rem !important;
  }
  .mr-md--7,
  .mx-md--7 {
    margin-right: -6rem !important;
  }
  .mb-md--7,
  .my-md--7 {
    margin-bottom: -6rem !important;
  }
  .ml-md--7,
  .mx-md--7 {
    margin-left: -6rem !important;
  }
  .m-md--8 {
    margin: -7.5rem !important;
  }
  .mt-md--8,
  .my-md--8 {
    margin-top: -7.5rem !important;
  }
  .mr-md--8,
  .mx-md--8 {
    margin-right: -7.5rem !important;
  }
  .mb-md--8,
  .my-md--8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-md--8,
  .mx-md--8 {
    margin-left: -7.5rem !important;
  }
  .m-md--9 {
    margin: -9rem !important;
  }
  .mt-md--9,
  .my-md--9 {
    margin-top: -9rem !important;
  }
  .mr-md--9,
  .mx-md--9 {
    margin-right: -9rem !important;
  }
  .mb-md--9,
  .my-md--9 {
    margin-bottom: -9rem !important;
  }
  .ml-md--9,
  .mx-md--9 {
    margin-left: -9rem !important;
  }
  .m-md--10 {
    margin: -9.5rem !important;
  }
  .mt-md--10,
  .my-md--10 {
    margin-top: -9.5rem !important;
  }
  .mr-md--10,
  .mx-md--10 {
    margin-right: -9.5rem !important;
  }
  .mb-md--10,
  .my-md--10 {
    margin-bottom: -9.5rem !important;
  }
  .ml-md--10,
  .mx-md--10 {
    margin-left: -9.5rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 4.5rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 4.5rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 6rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 6rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 6rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 6rem !important;
  }
  .m-md-8 {
    margin: 7.5rem !important;
  }
  .mt-md-8,
  .my-md-8 {
    margin-top: 7.5rem !important;
  }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 7.5rem !important;
  }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 7.5rem !important;
  }
  .m-md-9 {
    margin: 9rem !important;
  }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important;
  }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important;
  }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important;
  }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important;
  }
  .m-md-10 {
    margin: 9.5rem !important;
  }
  .mt-md-10,
  .my-md-10 {
    margin-top: 9.5rem !important;
  }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 9.5rem !important;
  }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 9.5rem !important;
  }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 9.5rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: .25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: .25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: .25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: .25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: .25rem !important;
  }
  .p-md-2 {
    padding: .5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: .5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: .5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: .5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: .5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md--1 {
    padding: -.25rem !important;
  }
  .pt-md--1,
  .py-md--1 {
    padding-top: -.25rem !important;
  }
  .pr-md--1,
  .px-md--1 {
    padding-right: -.25rem !important;
  }
  .pb-md--1,
  .py-md--1 {
    padding-bottom: -.25rem !important;
  }
  .pl-md--1,
  .px-md--1 {
    padding-left: -.25rem !important;
  }
  .p-md--2 {
    padding: -.5rem !important;
  }
  .pt-md--2,
  .py-md--2 {
    padding-top: -.5rem !important;
  }
  .pr-md--2,
  .px-md--2 {
    padding-right: -.5rem !important;
  }
  .pb-md--2,
  .py-md--2 {
    padding-bottom: -.5rem !important;
  }
  .pl-md--2,
  .px-md--2 {
    padding-left: -.5rem !important;
  }
  .p-md--3 {
    padding: 1rem !important;
  }
  .pt-md--3,
  .py-md--3 {
    padding-top: 1rem !important;
  }
  .pr-md--3,
  .px-md--3 {
    padding-right: 1rem !important;
  }
  .pb-md--3,
  .py-md--3 {
    padding-bottom: 1rem !important;
  }
  .pl-md--3,
  .px-md--3 {
    padding-left: 1rem !important;
  }
  .p-md--4 {
    padding: -1.5rem !important;
  }
  .pt-md--4,
  .py-md--4 {
    padding-top: -1.5rem !important;
  }
  .pr-md--4,
  .px-md--4 {
    padding-right: -1.5rem !important;
  }
  .pb-md--4,
  .py-md--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-md--4,
  .px-md--4 {
    padding-left: -1.5rem !important;
  }
  .p-md--5 {
    padding: -3rem !important;
  }
  .pt-md--5,
  .py-md--5 {
    padding-top: -3rem !important;
  }
  .pr-md--5,
  .px-md--5 {
    padding-right: -3rem !important;
  }
  .pb-md--5,
  .py-md--5 {
    padding-bottom: -3rem !important;
  }
  .pl-md--5,
  .px-md--5 {
    padding-left: -3rem !important;
  }
  .p-md--6 {
    padding: -4.5rem !important;
  }
  .pt-md--6,
  .py-md--6 {
    padding-top: -4.5rem !important;
  }
  .pr-md--6,
  .px-md--6 {
    padding-right: -4.5rem !important;
  }
  .pb-md--6,
  .py-md--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-md--6,
  .px-md--6 {
    padding-left: -4.5rem !important;
  }
  .p-md--7 {
    padding: -6rem !important;
  }
  .pt-md--7,
  .py-md--7 {
    padding-top: -6rem !important;
  }
  .pr-md--7,
  .px-md--7 {
    padding-right: -6rem !important;
  }
  .pb-md--7,
  .py-md--7 {
    padding-bottom: -6rem !important;
  }
  .pl-md--7,
  .px-md--7 {
    padding-left: -6rem !important;
  }
  .p-md--8 {
    padding: -7.5rem !important;
  }
  .pt-md--8,
  .py-md--8 {
    padding-top: -7.5rem !important;
  }
  .pr-md--8,
  .px-md--8 {
    padding-right: -7.5rem !important;
  }
  .pb-md--8,
  .py-md--8 {
    padding-bottom: -7.5rem !important;
  }
  .pl-md--8,
  .px-md--8 {
    padding-left: -7.5rem !important;
  }
  .p-md--9 {
    padding: -9rem !important;
  }
  .pt-md--9,
  .py-md--9 {
    padding-top: -9rem !important;
  }
  .pr-md--9,
  .px-md--9 {
    padding-right: -9rem !important;
  }
  .pb-md--9,
  .py-md--9 {
    padding-bottom: -9rem !important;
  }
  .pl-md--9,
  .px-md--9 {
    padding-left: -9rem !important;
  }
  .p-md--10 {
    padding: -9.5rem !important;
  }
  .pt-md--10,
  .py-md--10 {
    padding-top: -9.5rem !important;
  }
  .pr-md--10,
  .px-md--10 {
    padding-right: -9.5rem !important;
  }
  .pb-md--10,
  .py-md--10 {
    padding-bottom: -9.5rem !important;
  }
  .pl-md--10,
  .px-md--10 {
    padding-left: -9.5rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 4.5rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 4.5rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 6rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 6rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 6rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 6rem !important;
  }
  .p-md-8 {
    padding: 7.5rem !important;
  }
  .pt-md-8,
  .py-md-8 {
    padding-top: 7.5rem !important;
  }
  .pr-md-8,
  .px-md-8 {
    padding-right: 7.5rem !important;
  }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-md-8,
  .px-md-8 {
    padding-left: 7.5rem !important;
  }
  .p-md-9 {
    padding: 9rem !important;
  }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important;
  }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important;
  }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important;
  }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important;
  }
  .p-md-10 {
    padding: 9.5rem !important;
  }
  .pt-md-10,
  .py-md-10 {
    padding-top: 9.5rem !important;
  }
  .pr-md-10,
  .px-md-10 {
    padding-right: 9.5rem !important;
  }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 9.5rem !important;
  }
  .pl-md-10,
  .px-md-10 {
    padding-left: 9.5rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: .25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: .25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: .25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: .25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: .25rem !important;
  }
  .m-lg-2 {
    margin: .5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: .5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: .5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: .5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: .5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg--1 {
    margin: -.25rem !important;
  }
  .mt-lg--1,
  .my-lg--1 {
    margin-top: -.25rem !important;
  }
  .mr-lg--1,
  .mx-lg--1 {
    margin-right: -.25rem !important;
  }
  .mb-lg--1,
  .my-lg--1 {
    margin-bottom: -.25rem !important;
  }
  .ml-lg--1,
  .mx-lg--1 {
    margin-left: -.25rem !important;
  }
  .m-lg--2 {
    margin: -.5rem !important;
  }
  .mt-lg--2,
  .my-lg--2 {
    margin-top: -.5rem !important;
  }
  .mr-lg--2,
  .mx-lg--2 {
    margin-right: -.5rem !important;
  }
  .mb-lg--2,
  .my-lg--2 {
    margin-bottom: -.5rem !important;
  }
  .ml-lg--2,
  .mx-lg--2 {
    margin-left: -.5rem !important;
  }
  .m-lg--3 {
    margin: 1rem !important;
  }
  .mt-lg--3,
  .my-lg--3 {
    margin-top: 1rem !important;
  }
  .mr-lg--3,
  .mx-lg--3 {
    margin-right: 1rem !important;
  }
  .mb-lg--3,
  .my-lg--3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg--3,
  .mx-lg--3 {
    margin-left: 1rem !important;
  }
  .m-lg--4 {
    margin: -1.5rem !important;
  }
  .mt-lg--4,
  .my-lg--4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg--4,
  .mx-lg--4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg--4,
  .my-lg--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg--4,
  .mx-lg--4 {
    margin-left: -1.5rem !important;
  }
  .m-lg--5 {
    margin: -3rem !important;
  }
  .mt-lg--5,
  .my-lg--5 {
    margin-top: -3rem !important;
  }
  .mr-lg--5,
  .mx-lg--5 {
    margin-right: -3rem !important;
  }
  .mb-lg--5,
  .my-lg--5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg--5,
  .mx-lg--5 {
    margin-left: -3rem !important;
  }
  .m-lg--6 {
    margin: -4.5rem !important;
  }
  .mt-lg--6,
  .my-lg--6 {
    margin-top: -4.5rem !important;
  }
  .mr-lg--6,
  .mx-lg--6 {
    margin-right: -4.5rem !important;
  }
  .mb-lg--6,
  .my-lg--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-lg--6,
  .mx-lg--6 {
    margin-left: -4.5rem !important;
  }
  .m-lg--7 {
    margin: -6rem !important;
  }
  .mt-lg--7,
  .my-lg--7 {
    margin-top: -6rem !important;
  }
  .mr-lg--7,
  .mx-lg--7 {
    margin-right: -6rem !important;
  }
  .mb-lg--7,
  .my-lg--7 {
    margin-bottom: -6rem !important;
  }
  .ml-lg--7,
  .mx-lg--7 {
    margin-left: -6rem !important;
  }
  .m-lg--8 {
    margin: -7.5rem !important;
  }
  .mt-lg--8,
  .my-lg--8 {
    margin-top: -7.5rem !important;
  }
  .mr-lg--8,
  .mx-lg--8 {
    margin-right: -7.5rem !important;
  }
  .mb-lg--8,
  .my-lg--8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-lg--8,
  .mx-lg--8 {
    margin-left: -7.5rem !important;
  }
  .m-lg--9 {
    margin: -9rem !important;
  }
  .mt-lg--9,
  .my-lg--9 {
    margin-top: -9rem !important;
  }
  .mr-lg--9,
  .mx-lg--9 {
    margin-right: -9rem !important;
  }
  .mb-lg--9,
  .my-lg--9 {
    margin-bottom: -9rem !important;
  }
  .ml-lg--9,
  .mx-lg--9 {
    margin-left: -9rem !important;
  }
  .m-lg--10 {
    margin: -9.5rem !important;
  }
  .mt-lg--10,
  .my-lg--10 {
    margin-top: -9.5rem !important;
  }
  .mr-lg--10,
  .mx-lg--10 {
    margin-right: -9.5rem !important;
  }
  .mb-lg--10,
  .my-lg--10 {
    margin-bottom: -9.5rem !important;
  }
  .ml-lg--10,
  .mx-lg--10 {
    margin-left: -9.5rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 4.5rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 6rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 6rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 6rem !important;
  }
  .m-lg-8 {
    margin: 7.5rem !important;
  }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 7.5rem !important;
  }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 7.5rem !important;
  }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 7.5rem !important;
  }
  .m-lg-9 {
    margin: 9rem !important;
  }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important;
  }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important;
  }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important;
  }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important;
  }
  .m-lg-10 {
    margin: 9.5rem !important;
  }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 9.5rem !important;
  }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 9.5rem !important;
  }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 9.5rem !important;
  }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 9.5rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: .25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: .25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: .25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: .25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: .25rem !important;
  }
  .p-lg-2 {
    padding: .5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: .5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: .5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: .5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: .5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg--1 {
    padding: -.25rem !important;
  }
  .pt-lg--1,
  .py-lg--1 {
    padding-top: -.25rem !important;
  }
  .pr-lg--1,
  .px-lg--1 {
    padding-right: -.25rem !important;
  }
  .pb-lg--1,
  .py-lg--1 {
    padding-bottom: -.25rem !important;
  }
  .pl-lg--1,
  .px-lg--1 {
    padding-left: -.25rem !important;
  }
  .p-lg--2 {
    padding: -.5rem !important;
  }
  .pt-lg--2,
  .py-lg--2 {
    padding-top: -.5rem !important;
  }
  .pr-lg--2,
  .px-lg--2 {
    padding-right: -.5rem !important;
  }
  .pb-lg--2,
  .py-lg--2 {
    padding-bottom: -.5rem !important;
  }
  .pl-lg--2,
  .px-lg--2 {
    padding-left: -.5rem !important;
  }
  .p-lg--3 {
    padding: 1rem !important;
  }
  .pt-lg--3,
  .py-lg--3 {
    padding-top: 1rem !important;
  }
  .pr-lg--3,
  .px-lg--3 {
    padding-right: 1rem !important;
  }
  .pb-lg--3,
  .py-lg--3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg--3,
  .px-lg--3 {
    padding-left: 1rem !important;
  }
  .p-lg--4 {
    padding: -1.5rem !important;
  }
  .pt-lg--4,
  .py-lg--4 {
    padding-top: -1.5rem !important;
  }
  .pr-lg--4,
  .px-lg--4 {
    padding-right: -1.5rem !important;
  }
  .pb-lg--4,
  .py-lg--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-lg--4,
  .px-lg--4 {
    padding-left: -1.5rem !important;
  }
  .p-lg--5 {
    padding: -3rem !important;
  }
  .pt-lg--5,
  .py-lg--5 {
    padding-top: -3rem !important;
  }
  .pr-lg--5,
  .px-lg--5 {
    padding-right: -3rem !important;
  }
  .pb-lg--5,
  .py-lg--5 {
    padding-bottom: -3rem !important;
  }
  .pl-lg--5,
  .px-lg--5 {
    padding-left: -3rem !important;
  }
  .p-lg--6 {
    padding: -4.5rem !important;
  }
  .pt-lg--6,
  .py-lg--6 {
    padding-top: -4.5rem !important;
  }
  .pr-lg--6,
  .px-lg--6 {
    padding-right: -4.5rem !important;
  }
  .pb-lg--6,
  .py-lg--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-lg--6,
  .px-lg--6 {
    padding-left: -4.5rem !important;
  }
  .p-lg--7 {
    padding: -6rem !important;
  }
  .pt-lg--7,
  .py-lg--7 {
    padding-top: -6rem !important;
  }
  .pr-lg--7,
  .px-lg--7 {
    padding-right: -6rem !important;
  }
  .pb-lg--7,
  .py-lg--7 {
    padding-bottom: -6rem !important;
  }
  .pl-lg--7,
  .px-lg--7 {
    padding-left: -6rem !important;
  }
  .p-lg--8 {
    padding: -7.5rem !important;
  }
  .pt-lg--8,
  .py-lg--8 {
    padding-top: -7.5rem !important;
  }
  .pr-lg--8,
  .px-lg--8 {
    padding-right: -7.5rem !important;
  }
  .pb-lg--8,
  .py-lg--8 {
    padding-bottom: -7.5rem !important;
  }
  .pl-lg--8,
  .px-lg--8 {
    padding-left: -7.5rem !important;
  }
  .p-lg--9 {
    padding: -9rem !important;
  }
  .pt-lg--9,
  .py-lg--9 {
    padding-top: -9rem !important;
  }
  .pr-lg--9,
  .px-lg--9 {
    padding-right: -9rem !important;
  }
  .pb-lg--9,
  .py-lg--9 {
    padding-bottom: -9rem !important;
  }
  .pl-lg--9,
  .px-lg--9 {
    padding-left: -9rem !important;
  }
  .p-lg--10 {
    padding: -9.5rem !important;
  }
  .pt-lg--10,
  .py-lg--10 {
    padding-top: -9.5rem !important;
  }
  .pr-lg--10,
  .px-lg--10 {
    padding-right: -9.5rem !important;
  }
  .pb-lg--10,
  .py-lg--10 {
    padding-bottom: -9.5rem !important;
  }
  .pl-lg--10,
  .px-lg--10 {
    padding-left: -9.5rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 6rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 6rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 6rem !important;
  }
  .p-lg-8 {
    padding: 7.5rem !important;
  }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 7.5rem !important;
  }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 7.5rem !important;
  }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 7.5rem !important;
  }
  .p-lg-9 {
    padding: 9rem !important;
  }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important;
  }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important;
  }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important;
  }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important;
  }
  .p-lg-10 {
    padding: 9.5rem !important;
  }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 9.5rem !important;
  }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 9.5rem !important;
  }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 9.5rem !important;
  }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 9.5rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: .25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: .25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: .25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: .25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: .25rem !important;
  }
  .m-xl-2 {
    margin: .5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: .5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: .5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: .5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: .5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .m-xl--1 {
    margin: -.25rem !important;
  }
  .mt-xl--1,
  .my-xl--1 {
    margin-top: -.25rem !important;
  }
  .mr-xl--1,
  .mx-xl--1 {
    margin-right: -.25rem !important;
  }
  .mb-xl--1,
  .my-xl--1 {
    margin-bottom: -.25rem !important;
  }
  .ml-xl--1,
  .mx-xl--1 {
    margin-left: -.25rem !important;
  }
  .m-xl--2 {
    margin: -.5rem !important;
  }
  .mt-xl--2,
  .my-xl--2 {
    margin-top: -.5rem !important;
  }
  .mr-xl--2,
  .mx-xl--2 {
    margin-right: -.5rem !important;
  }
  .mb-xl--2,
  .my-xl--2 {
    margin-bottom: -.5rem !important;
  }
  .ml-xl--2,
  .mx-xl--2 {
    margin-left: -.5rem !important;
  }
  .m-xl--3 {
    margin: 1rem !important;
  }
  .mt-xl--3,
  .my-xl--3 {
    margin-top: 1rem !important;
  }
  .mr-xl--3,
  .mx-xl--3 {
    margin-right: 1rem !important;
  }
  .mb-xl--3,
  .my-xl--3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl--3,
  .mx-xl--3 {
    margin-left: 1rem !important;
  }
  .m-xl--4 {
    margin: -1.5rem !important;
  }
  .mt-xl--4,
  .my-xl--4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl--4,
  .mx-xl--4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl--4,
  .my-xl--4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl--4,
  .mx-xl--4 {
    margin-left: -1.5rem !important;
  }
  .m-xl--5 {
    margin: -3rem !important;
  }
  .mt-xl--5,
  .my-xl--5 {
    margin-top: -3rem !important;
  }
  .mr-xl--5,
  .mx-xl--5 {
    margin-right: -3rem !important;
  }
  .mb-xl--5,
  .my-xl--5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl--5,
  .mx-xl--5 {
    margin-left: -3rem !important;
  }
  .m-xl--6 {
    margin: -4.5rem !important;
  }
  .mt-xl--6,
  .my-xl--6 {
    margin-top: -4.5rem !important;
  }
  .mr-xl--6,
  .mx-xl--6 {
    margin-right: -4.5rem !important;
  }
  .mb-xl--6,
  .my-xl--6 {
    margin-bottom: -4.5rem !important;
  }
  .ml-xl--6,
  .mx-xl--6 {
    margin-left: -4.5rem !important;
  }
  .m-xl--7 {
    margin: -6rem !important;
  }
  .mt-xl--7,
  .my-xl--7 {
    margin-top: -6rem !important;
  }
  .mr-xl--7,
  .mx-xl--7 {
    margin-right: -6rem !important;
  }
  .mb-xl--7,
  .my-xl--7 {
    margin-bottom: -6rem !important;
  }
  .ml-xl--7,
  .mx-xl--7 {
    margin-left: -6rem !important;
  }
  .m-xl--8 {
    margin: -7.5rem !important;
  }
  .mt-xl--8,
  .my-xl--8 {
    margin-top: -7.5rem !important;
  }
  .mr-xl--8,
  .mx-xl--8 {
    margin-right: -7.5rem !important;
  }
  .mb-xl--8,
  .my-xl--8 {
    margin-bottom: -7.5rem !important;
  }
  .ml-xl--8,
  .mx-xl--8 {
    margin-left: -7.5rem !important;
  }
  .m-xl--9 {
    margin: -9rem !important;
  }
  .mt-xl--9,
  .my-xl--9 {
    margin-top: -9rem !important;
  }
  .mr-xl--9,
  .mx-xl--9 {
    margin-right: -9rem !important;
  }
  .mb-xl--9,
  .my-xl--9 {
    margin-bottom: -9rem !important;
  }
  .ml-xl--9,
  .mx-xl--9 {
    margin-left: -9rem !important;
  }
  .m-xl--10 {
    margin: -9.5rem !important;
  }
  .mt-xl--10,
  .my-xl--10 {
    margin-top: -9.5rem !important;
  }
  .mr-xl--10,
  .mx-xl--10 {
    margin-right: -9.5rem !important;
  }
  .mb-xl--10,
  .my-xl--10 {
    margin-bottom: -9.5rem !important;
  }
  .ml-xl--10,
  .mx-xl--10 {
    margin-left: -9.5rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 4.5rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 6rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 6rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 6rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 6rem !important;
  }
  .m-xl-8 {
    margin: 7.5rem !important;
  }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 7.5rem !important;
  }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 7.5rem !important;
  }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 7.5rem !important;
  }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 7.5rem !important;
  }
  .m-xl-9 {
    margin: 9rem !important;
  }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important;
  }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important;
  }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important;
  }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important;
  }
  .m-xl-10 {
    margin: 9.5rem !important;
  }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 9.5rem !important;
  }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 9.5rem !important;
  }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 9.5rem !important;
  }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 9.5rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: .25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: .25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: .25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: .25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: .25rem !important;
  }
  .p-xl-2 {
    padding: .5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: .5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: .5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: .5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: .5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .p-xl--1 {
    padding: -.25rem !important;
  }
  .pt-xl--1,
  .py-xl--1 {
    padding-top: -.25rem !important;
  }
  .pr-xl--1,
  .px-xl--1 {
    padding-right: -.25rem !important;
  }
  .pb-xl--1,
  .py-xl--1 {
    padding-bottom: -.25rem !important;
  }
  .pl-xl--1,
  .px-xl--1 {
    padding-left: -.25rem !important;
  }
  .p-xl--2 {
    padding: -.5rem !important;
  }
  .pt-xl--2,
  .py-xl--2 {
    padding-top: -.5rem !important;
  }
  .pr-xl--2,
  .px-xl--2 {
    padding-right: -.5rem !important;
  }
  .pb-xl--2,
  .py-xl--2 {
    padding-bottom: -.5rem !important;
  }
  .pl-xl--2,
  .px-xl--2 {
    padding-left: -.5rem !important;
  }
  .p-xl--3 {
    padding: 1rem !important;
  }
  .pt-xl--3,
  .py-xl--3 {
    padding-top: 1rem !important;
  }
  .pr-xl--3,
  .px-xl--3 {
    padding-right: 1rem !important;
  }
  .pb-xl--3,
  .py-xl--3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl--3,
  .px-xl--3 {
    padding-left: 1rem !important;
  }
  .p-xl--4 {
    padding: -1.5rem !important;
  }
  .pt-xl--4,
  .py-xl--4 {
    padding-top: -1.5rem !important;
  }
  .pr-xl--4,
  .px-xl--4 {
    padding-right: -1.5rem !important;
  }
  .pb-xl--4,
  .py-xl--4 {
    padding-bottom: -1.5rem !important;
  }
  .pl-xl--4,
  .px-xl--4 {
    padding-left: -1.5rem !important;
  }
  .p-xl--5 {
    padding: -3rem !important;
  }
  .pt-xl--5,
  .py-xl--5 {
    padding-top: -3rem !important;
  }
  .pr-xl--5,
  .px-xl--5 {
    padding-right: -3rem !important;
  }
  .pb-xl--5,
  .py-xl--5 {
    padding-bottom: -3rem !important;
  }
  .pl-xl--5,
  .px-xl--5 {
    padding-left: -3rem !important;
  }
  .p-xl--6 {
    padding: -4.5rem !important;
  }
  .pt-xl--6,
  .py-xl--6 {
    padding-top: -4.5rem !important;
  }
  .pr-xl--6,
  .px-xl--6 {
    padding-right: -4.5rem !important;
  }
  .pb-xl--6,
  .py-xl--6 {
    padding-bottom: -4.5rem !important;
  }
  .pl-xl--6,
  .px-xl--6 {
    padding-left: -4.5rem !important;
  }
  .p-xl--7 {
    padding: -6rem !important;
  }
  .pt-xl--7,
  .py-xl--7 {
    padding-top: -6rem !important;
  }
  .pr-xl--7,
  .px-xl--7 {
    padding-right: -6rem !important;
  }
  .pb-xl--7,
  .py-xl--7 {
    padding-bottom: -6rem !important;
  }
  .pl-xl--7,
  .px-xl--7 {
    padding-left: -6rem !important;
  }
  .p-xl--8 {
    padding: -7.5rem !important;
  }
  .pt-xl--8,
  .py-xl--8 {
    padding-top: -7.5rem !important;
  }
  .pr-xl--8,
  .px-xl--8 {
    padding-right: -7.5rem !important;
  }
  .pb-xl--8,
  .py-xl--8 {
    padding-bottom: -7.5rem !important;
  }
  .pl-xl--8,
  .px-xl--8 {
    padding-left: -7.5rem !important;
  }
  .p-xl--9 {
    padding: -9rem !important;
  }
  .pt-xl--9,
  .py-xl--9 {
    padding-top: -9rem !important;
  }
  .pr-xl--9,
  .px-xl--9 {
    padding-right: -9rem !important;
  }
  .pb-xl--9,
  .py-xl--9 {
    padding-bottom: -9rem !important;
  }
  .pl-xl--9,
  .px-xl--9 {
    padding-left: -9rem !important;
  }
  .p-xl--10 {
    padding: -9.5rem !important;
  }
  .pt-xl--10,
  .py-xl--10 {
    padding-top: -9.5rem !important;
  }
  .pr-xl--10,
  .px-xl--10 {
    padding-right: -9.5rem !important;
  }
  .pb-xl--10,
  .py-xl--10 {
    padding-bottom: -9.5rem !important;
  }
  .pl-xl--10,
  .px-xl--10 {
    padding-left: -9.5rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 6rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 6rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 6rem !important;
  }
  .p-xl-8 {
    padding: 7.5rem !important;
  }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 7.5rem !important;
  }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 7.5rem !important;
  }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 7.5rem !important;
  }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 7.5rem !important;
  }
  .p-xl-9 {
    padding: 9rem !important;
  }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important;
  }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important;
  }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important;
  }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important;
  }
  .p-xl-10 {
    padding: 9.5rem !important;
  }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 9.5rem !important;
  }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 9.5rem !important;
  }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 9.5rem !important;
  }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 9.5rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .text-sm-xs {
    font-size: .6875rem;
  }
  .text-sm-sm {
    font-size: .8125rem;
  }
  .text-sm-lg {
    font-size: 1.0625rem;
  }
  .text-sm-xl {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #d2aa78 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #c59252 !important;
}

.text-secondary {
  color: #828a91 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #697077 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #828a91 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important;
}

.text-hide {
  font: 0/0 a;

  color: transparent;
  border: 0;
  background-color: transparent;
  text-shadow: none;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;

    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.alert {
  color: #212529;
  border-width: 2px 0 0 0;
  background-color: white;
  box-shadow: 0 25px 100px -5px rgba(0, 0, 0, .15);
}

.alert-fixed {
  position: fixed;
  z-index: 1030;
  top: 5.8125rem;
  left: 50%;

  width: 100%;
  max-width: 40rem;

  transform: translateX(-50%);
  text-align: center;
}

@media (min-width: 768px) {
  .alert-fixed {
    top: calc(5.8125rem + 1rem);
  }
}

// .breadcrumb {
//   margin-top: 5.8125rem;
// }

.breadcrumb-heading {
  font-family: 'Objective', sans-serif;
  font-weight: 300;

  margin-bottom: 0;

  color: white;
}

.breadcrumb-item {
  font-size: .79688rem;
}

.breadcrumb-item:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

.btn {
  font-size: .6875rem;
  font-weight: 500;

  position: relative;

  text-transform: uppercase;
}

.btn[class*='outline'] {
  color: #212529;
}

.btn[class*='outline'].dropdown-toggle {
  background-color: transparent;
}

.btn[class*='outline']:hover,
.btn[class*='outline']:focus,
.btn[class*='outline']:active,
.btn[class*='outline']:active:focus {
  background-color: transparent;
}

.btn[class*='outline'][class*='primary']:hover,
.btn[class*='outline'][class*='primary']:focus,
.btn[class*='outline'][class*='primary']:active,
.btn[class*='outline'][class*='primary']:active:focus {
  color: #d2aa78;
}

.btn-circle {
  display: inline-flex;

  width: 4.20313rem !important;
  height: 4.20313rem !important;
  padding: 0 !important;

  border-radius: 50%;

  align-items: center;
  justify-content: center;
}

.btn-circle.btn-lg,
.btn-group-lg > .btn-circle.btn {
  width: 4.70313rem !important;
  height: 4.70313rem !important;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
  width: 3.70313rem !important;
  height: 3.70313rem !important;
}

.btn.btn-link {
  color: #212529;
}

.btn:focus {
  box-shadow: none !important;
}

.card {
  display: block;
}

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .card {
    display: flex;
  }
}

a.card {
  transition: all .2s ease-in-out;
  transition-property: border-color;
}

a.card:hover,
a.card:focus,
a.card:active,
a.card:focus:active {
  text-decoration: none;

  border-color: #d2aa78;
}

a.card.disabled {
  pointer-events: none;

  opacity: .5;
}

@media (min-width: 768px) {
  .card-stretch {
    height: 100%;
  }
}

.card-tall {
  padding-top: 50%;
}

.card-active {
  border-top: 2px solid #d2aa78;
}

@media (min-width: 768px) {
  .card-standout {
    z-index: 1060;

    transform: scale(1.05);
    transform-origin: center center;
  }
  .card-standout .card-body {
    transform: scale(.95);
  }
}

a.card .card-img-top,
.card-img-bottom {
  position: relative;

  transition: all .2s ease-in-out;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

a.card .card-img-top:before,
.card-img-bottom:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
  transition: inherit;

  opacity: 0;
  box-shadow: 0 1rem 2rem -1rem rgba(0, 0, 0, .75);
}

a.card:hover .card-img-top,
.card-img-bottom {
  transform: translateY(-.25rem);
}

a.card:hover .card-img-top:before,
.card-img-bottom:before {
  opacity: 1;
}

.card-img-waiting {
  background-color: #ced4da;
}

.card-img-waiting:after {
  position: relative;

  display: block;

  width: 100%;
  height: 100%;
  padding-bottom: 61%;

  content: '';
}

.card-img-waiting > [class*='icon'] {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  color: #828a91;
}

.card-body {
  position: relative;
}

.card-stretch .card-body {
  display: inherit;
  flex-direction: inherit;

  justify-content: center;
}

.card-lg .card-body {
  padding: 3.5rem 2.5rem;
}

.card-sm .card-body {
  padding: 2.5rem 1.5rem;
}

.card-lg .card-title {
  margin-bottom: 1.5rem;
}

.card-sm .card-title {
  margin-bottom: 1rem;
}

.card-order {
  margin-bottom: 1rem;
}

.card-order > span {
  display: inline-block;

  -webkit-clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
}

.card-lg .card-order {
  margin-bottom: 1.5rem;
}

.card-sm .card-order {
  margin-bottom: 1rem;
}

.card-icon {
  font-size: 2em;

  margin-bottom: 1rem;
}

.card-lg .card-icon {
  margin-bottom: 1.5rem;
}

.card-sm .card-icon {
  margin-bottom: 1rem;
}

.card-text {
  margin-bottom: 1rem;
}

.card-lg .card-text {
  margin-bottom: 1.5rem;
}

.card-sm .card-text {
  margin-bottom: 1rem;
}

.dropdown-menu {
  opacity: 0;
}

.dropdown-menu.show {
  animation: .2s forwards fade-in;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

.dropdown-item {
  font-size: .6875rem;
  font-weight: 500;

  padding-top: .5rem;
  padding-bottom: .5rem;

  transition: background-color .1s;
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .dropdown-item {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }
}

@media (min-width: 1200px) {
  .dropdown-item + .dropdown-item {
    border-top: 1px solid rgba(0, 0, 0, .1);
  }
}

.input-group-text {
  transition: border-color .15s;
}

.form-light .input-group-text {
  color: rgba(255, 255, 255, .5);
  border-color: rgba(255, 255, 255, .25);
  background-color: rgba(255, 255, 255, .05);
}

.input-group-icon {
  width: 1em;
  height: 1em;
}

.input-group-icon > path {
  stroke-width: 10 !important;
}

.input-group-append.order-0 > .input-group-text {
  border-right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-light .form-control {
  color: white;
  border-color: rgba(255, 255, 255, .25);
  background-color: rgba(255, 255, 255, .05);
}

.form-light .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .5);
}

.form-light .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, .5);
}

.form-light .form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, .5);
}

.form-light .form-control::placeholder {
  color: rgba(255, 255, 255, .5);
}

.form-control.order-1 {
  border-left: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0;
}

.form-control:not(:focus) + .input-group-append .input-group-icon path {
  stroke-dashoffset: 0;
}

.form-control:focus {
  background-color: white;
}

.form-light .form-control:focus {
  border-color: #d2aa78;
  background-color: transparent;
}

.form-control:focus + .input-group-append .input-group-text {
  color: #d2aa78;
  border-color: #d2aa78;
  background-color: white;
}

.form-light .form-control:focus + .input-group-append .input-group-text {
  background-color: transparent;
}

.form-control:focus + .input-group-append .input-group-icon > path {
  animation: icon .5s linear forwards;
}

@keyframes icon {
  to {
    stroke-dashoffset: 0;
  }
}

.form-styled {
  padding: 2.5rem 1.5rem;

  border: 1px solid rgba(0, 0, 0, .1);
  border-top: 2px solid #d2aa78;
  box-shadow: 0 25px 100px -5px rgba(0, 0, 0, .15);
}

@media (min-width: 768px) {
  .form-styled {
    padding: 3.5rem 2.5rem;
  }
}

label {
  font-size: .6875rem;
  font-weight: 500;

  text-transform: uppercase;

  color: #828a91;
}

.form-light label {
  color: rgba(255, 255, 255, .5);
}

.extended-radio-label {
  font-size: 1.125rem;

  display: block;

  padding: 1rem 1.5rem;

  cursor: pointer;
  transition: all .2s ease-in-out;
  transition-property: border-color, color;
  text-align: center;

  color: #828a91;
  border: 1px solid #dee2e6;
}

.extended-radio-label:hover,
.extended-radio-label:focus,
.extended-radio-label:active,
.extended-radio-label:active:focus {
  text-decoration: none;

  color: #828a91;
  border-color: #d2aa78;
}

.extended-radio-input {
  display: none;
}

.extended-radio-input:checked + .extended-radio-label {
  color: #d2aa78;
  border-color: #d2aa78;
}

#mce-hidden-input {
  display: none;
}

.custom-control-label:before,
.custom-control-label:after {
  top: 50%;

  transform: translateY(-50%);
}

.img-effect {
  position: relative;
}

.img-effect > img {
  position: relative;
  z-index: 1;

  box-shadow: 0 25px 100px -5px rgba(0, 0, 0, .15);
}

.img-effect-solid {
  padding-right: 10%;
  padding-left: 20%;
}

.img-effect-solid:before {
  position: absolute;
  top: 35%;
  right: 0;
  bottom: 10%;
  left: 0;

  content: '';

  background-color: #dbbb93;
}

.img-effect-border {
  padding-right: 20%;
  padding-left: 10%;
}

.img-effect-border:before {
  position: absolute;
  top: 75%;
  right: 10%;
  bottom: -10%;
  left: 35%;

  content: '';

  background-color: #dbbb93;
}

.img-effect-border:after {
  position: absolute;
  top: -10%;
  right: 55%;
  bottom: 75%;
  left: 0;

  content: '';

  border: 2px solid #dbbb93;
}

@media (min-width: 768px) {
  .img-effect-info {
    padding-right: 15%;
    padding-bottom: 10%;
    padding-left: 15%;
  }
}

.img-effect-info-popup {
  z-index: 2;

  margin-bottom: 1.5rem;
  padding: 2rem 1.5rem;

  text-align: center;

  color: white;
  border-radius: .25rem;
  background-color: #d2aa78;
  box-shadow: 0 25px 100px -5px rgba(0, 0, 0, .15);
}

@media (min-width: 768px) {
  .img-effect-info-popup {
    position: absolute;
    right: 0;
    bottom: 0;

    width: 50%;
    height: auto;
    margin-bottom: 0;
  }
}

.img-effect-info-popup-icon {
  display: inline-flex;

  width: 3rem;
  height: 3rem;
  margin-bottom: 1rem;

  border-radius: 50%;
  background-color: rgba(0, 0, 0, .05);

  align-items: center;
  justify-content: center;
}

.img-effect-dotted {
  padding: 10% 15% 0 15%;
}

.img-effect-dotted:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50%;
  left: 50%;

  content: '';

  background-image: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\' viewBox=\'0 0 100 100\'><circle cx=\'50\' cy=\'50\' r=\'15\' fill=\'%23d2aa78\'></circle></svg>');
  background-size: .5rem;
}

.modal-dialog-video .modal-content {
  color: white;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.modal-dialog-video .close {
  color: white;
  text-shadow: none;
}

.modal.fade .modal-dialog {
  transform: scale(.95);

  opacity: 0;
}

.modal.show .modal-dialog {
  transform: scale(1);

  opacity: 1;
}

.modal-body {
  padding-top: 0;
}

.nav-tabs .nav-item + .nav-item {
  margin-left: .5rem;
}

.nav-tabs .nav-link {
  font-size: .6875rem;
  font-weight: 500;

  padding: 1rem 1.5rem;

  text-transform: uppercase;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:active,
.nav-tabs .nav-link:focus:active {
  border-bottom-color: white;
}

.nav-tabs .nav-link.disabled {
  pointer-events: none;

  opacity: .5;
}

.nav-pills .nav-item + .nav-item {
  margin-left: .5rem;
}

.nav-pills .nav-link {
  font-size: .6875rem;
  font-weight: 500;

  padding: 1rem 1.5rem;

  transition: all .2s ease-in-out;
  text-transform: uppercase;

  color: #212529;
  border: 1px solid #d2aa78;
}

.nav-pills .nav-link:hover {
  color: #d2aa78;
}

.nav-pills .nav-link.active:hover {
  color: white;
}

.nav-pills .nav-link.disabled {
  pointer-events: none;

  opacity: .5;
}

.navbar:before {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
  transition: opacity .15s linear;

  opacity: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  background-color: white;
}

.navbar-dark {
  color: white;
}

.navbar-dark > .container {
  position: relative;
}

.navbar-dark > .container:after {
  position: absolute;
  right: 0;
  bottom: -1.25rem;
  left: 0;

  content: '';

  border-top: 1px solid rgba(255, 255, 255, .1);
}

.navbar-light {
  color: #212529;
}

.navbar-light:before {
  opacity: 1;
}

.navbar-brand {
  font-weight: 500;

  color: inherit !important;
}

.navbar-brand-svg {
  width: auto;
  width: 5.14063rem;
  height: 1.75rem;
}

.navbar-brand-img {
  width: auto;
  height: 1.75rem;
}

.navbar .nav-link {
  font-size: .75rem;
  font-weight: 500;

  position: relative;

  padding-top: 1rem;
  padding-bottom: 1rem;

  transition: all .2s ease-in-out;
  transition-property: color;
  text-transform: uppercase;
}

@media (min-width: 1200px) {
  .navbar .nav-link:before {
    position: absolute;
    right: 1rem;
    bottom: -1.25rem;
    left: 1rem;

    display: none;

    content: '';

    border-top: 2px solid #d2aa78;
  }
}

@media (min-width: 1200px) {
  .navbar .nav-link.active:before {
    display: block;
  }
}

@media (min-width: 1200px) {
  .navbar .nav-item.active > .nav-link:before {
    display: block;
  }
}

.navbar .nav-item-divider {
  display: none;
}

@media (min-width: 1200px) {
  .navbar .nav-item-divider {
    display: block;
  }
}

.navbar .nav-item-divider .nav-link {
  opacity: .25;
}

.navbar .nav-item-divider .nav-link > span {
  border-left: 1px solid;
}

@media (max-width: 1199.98px) {
  .navbar .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;

    border: 0;
  }
}

@media (min-width: 1200px) {
  .navbar .dropdown-menu {
    transform: translateY(.5rem);

    opacity: 0;
  }
  .navbar .dropdown-menu.show {
    animation: .2s forwards fade-up;
  }
  @keyframes fade-up {
    to {
      transform: translateY(0);

      opacity: 1;
    }
  }
}

.page-link {
  font-size: .6875rem !important;
  font-weight: 500;

  text-transform: uppercase;
}

html,
body {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Objective';
  font-weight: 600;

  src: url('../fonts/Objective/Objective-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Objective';
  font-weight: 500;

  src: url('../fonts/Objective/Objective-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Objective';
  font-weight: 400;

  src: url('../fonts/Objective/Objective-Regular.woff2') format('woff2');
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -.05em;
}

@media (max-width: 767.98px) {
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.375rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.125rem;
  }
  h5 {
    font-size: .9375rem;
  }
  h6 {
    font-size: .75rem;
  }
}

.lead {
  line-height: 1.75;
}

.text-xs {
  font-size: .6875rem;
}

.text-sm {
  font-size: .8125rem;
}

.text-lg {
  font-size: 1.0625rem;
}

.text-vertical {
  position: relative;

  transform-origin: right bottom;
}

@media (min-width: 768px) {
  .text-vertical {
    transform: rotate(-90deg) translateX(50%) translateX(3rem);
    white-space: nowrap;
  }
}

.text-vertical > span {
  position: relative;
}

.text-vertical > span:before {
  position: absolute;
  top: 50%;
  right: calc(100% + 1rem);

  width: 2rem;

  content: '';
  transform: translateY(50%);

  border-top: 2px solid;
}

.text-white.text-muted {
  color: rgba(255, 255, 255, .65) !important;
}

.text-zigzag {
  position: relative;
}

.text-zigzag:after {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;

  height: .175em;

  content: '';

  background-image: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'20\' height=\'10\'><path d=\'M0 0 L 10 9 L 20 1\' stroke=\'#d2aa78\' stroke-width=\'2\' fill=\'none\'></path></svg>');
  background-repeat: repeat;
  background-size: .35em .175em;
}

.blockquote {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;

  border-left: 1px solid #d2aa78;
}

.blockquote > footer {
  font-size: .8125rem;
}

.blockquote > footer > img {
  width: 3em;
  height: auto;
  margin-right: 1em;

  border-radius: 1.5em;
}

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .blockquote > footer > img {
    height: 3em;

    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
  }
}

.list-iconed {
  margin-bottom: 1.5rem;
  padding-left: 0;

  list-style-type: none;

  text-align: left;
}

.list-iconed li {
  display: flex;
}

.list-iconed li + li {
  margin-top: 1em;
}

.list-iconed li:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;

  margin-right: 1em;

  content: '\f105';

  color: #d2aa78;
}

.list-iconed2 {
  margin-bottom: 1.5rem;
  padding-left: 0;

  list-style-type: none;

  text-align: left;
}

.list-iconed2 li {
  display: flex;
}

.list-iconed2 li + li {
  margin-top: 1em;
}

.list-iconed2 li:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;

  margin-right: 1em;

  content: '\f00d';

  color: #d2aa78;
}

.list-iconed3 {
  margin-bottom: 1.5rem;
  padding-left: 25px;
  text-indent: -25px;
  list-style-type: none;
  text-align: left;
}


.list-iconed3 li + li {
  margin-top: 1em;
}

.list-iconed3 li:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;

  margin-right: 1em;

  content: '\f105';

  color: #d2aa78;
}

.code {
  max-height: 20rem;
}

::-moz-selection {
  color: white;
  background-color: #d2aa78;
}

::selection {
  color: white;
  background-color: #d2aa78;
}

.bg-slider {
  position: absolute;
  z-index: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-slider .slider {
  width: 100%;
  height: 100%;
}

.bg-slider .slider-item {
  width: inherit;
  height: inherit;
  padding: 0;
}

.bg-slider .slider .flickity-viewport {
  height: 100% !important;
}

.bg-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;
}

.bg-video-media {
  position: absolute;
  top: 50%;
  left: 50%;

  min-width: 100%;
  min-height: 100%;

  transform: translate(-50%, -50%);
}

@supports not (-ms-ime-align: auto) {
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .bg-video-media {
      top: 0;
      left: 0;

      width: 100%;
      min-width: none;
      height: 100%;
      min-height: none;

      transform: none;

      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}

.bg-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bg-overlay:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  opacity: .55;
  background-color: #212529;
}

.bg-triangle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% auto;
}

.bg-triangle-left {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><path d=\'M0 100 V 90 L 10 100 Z\' fill=\'%23212529\' fill-opacity=\'0.03\'></path><path d=\'M0 90 V 80 L 10 90 Z\' fill=\'%23212529\' fill-opacity=\'0.045\'></path><path d=\'M10 100 V 90 L 20 100 Z\' fill=\'%23212529\' fill-opacity=\'0.025\'></path><path d=\'M0 90 H 10 V 100 Z\' fill=\'%23212529\' fill-opacity=\'0.06\'></path></svg>');
}

.bg-triangle-left.bg-triangle-light {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><path d=\'M0 100 V 90 L 10 100 Z\' fill=\'white\' fill-opacity=\'0.03\'></path><path d=\'M0 90 V 80 L 10 90 Z\' fill=\'white\' fill-opacity=\'0.045\'></path><path d=\'M10 100 V 90 L 20 100 Z\' fill=\'white\' fill-opacity=\'0.025\'></path><path d=\'M0 90 H 10 V 100 Z\' fill=\'white\' fill-opacity=\'0.06\'></path></svg>');
}

.bg-triangle-right {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><path d=\'M100 100 V 90 L 90 100 Z\' fill=\'%23212529\' fill-opacity=\'0.045\'></path><path d=\'M100 90 V 80 L 90 90 Z\' fill=\'%23212529\' fill-opacity=\'0.015\'></path><path d=\'M90 100 V 90 L 80 100 Z\' fill=\'%23212529\' fill-opacity=\'0.03\'></path><path d=\'M90 100 V 90 H 100 Z\' fill=\'%23212529\' fill-opacity=\'0.06\'></path></svg>');
}

.bg-triangle-right.bg-triangle-light {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'100\' height=\'100\'><path d=\'M100 100 V 90 L 90 100 Z\' fill=\'white\' fill-opacity=\'0.045\'></path><path d=\'M100 90 V 80 L 90 90 Z\' fill=\'white\' fill-opacity=\'0.015\'></path><path d=\'M90 100 V 90 L 80 100 Z\' fill=\'white\' fill-opacity=\'0.03\'></path><path d=\'M90 100 V 90 H 100 Z\' fill=\'white\' fill-opacity=\'0.06\'></path></svg>');
}

.bg-triangle-top {
  transform: scaleY(-1);
}

.bg-incline {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-repeat: no-repeat;
  background-position: top right, top right, calc(80% - 100vmax) top, calc(90% - 50vmax) top;
  background-size: 20% 100%, 10% 100%, 500vmax 500vmax, 500vmax 500vmax;
}

@media all and (-ms-high-contrast: none) {
  .bg-incline {
    background-size: 0 0;
  }
}

.bg-incline-dark {
  background-image: linear-gradient(to right, rgba(33, 37, 41, .015) 0%, rgba(33, 37, 41, .015) 100%), linear-gradient(to right, rgba(33, 37, 41, .015) 0%, rgba(33, 37, 41, .015) 100%), linear-gradient(to top left, rgba(33, 37, 41, .015) 50%, transparent 50%), linear-gradient(to top left, rgba(33, 37, 41, .015) 50%, transparent 50%);
}

.bg-incline-light {
  background-image: linear-gradient(to right, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .05) 100%), linear-gradient(to right, rgba(255, 255, 255, .05) 0%, rgba(255, 255, 255, .05) 100%), linear-gradient(to top left, rgba(255, 255, 255, .05) 50%, transparent 50%), linear-gradient(to top left, rgba(255, 255, 255, .05) 50%, transparent 50%);
}

.bg-incline-left {
  transform: scaleX(-1);
}

.bg-incline-top {
  transform: scaleY(-1);
}

.bg-incline-top.bg-incline-left {
  transform: scale(-1);
}

.line {
  position: absolute;
  left: 50%;

  height: 5rem;

  transform: translateY(-50%);

  border-left: 2px solid #d2aa78;
}

@media (min-width: 768px) {
  .line {
    height: 7.5rem;
  }
}

.line-top {
  top: 0;
}

.line-bottom {
  top: 100%;
}

.box-shadow {
  box-shadow: 0 25px 100px -5px rgba(0, 0, 0, .15);
}

.square {
  position: relative;

  padding-bottom: 100%;
}

/*
@media (min-width: 768px) {
  @supports (--css: variables) {
    [data-toggle='animation'] {
      animation-duration: .4s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: forwards;
    }
    [data-animation='fadeIn'] {
      opacity: 0;
    }
    [data-animation='fadeIn'].animate {
      animation-name: fadeIn;
    }
    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }
    [data-animation='fadeUp'] {
      transform: translateY(1rem);

      opacity: 0;
    }
    [data-animation='fadeUp'].animate {
      animation-name: fadeUp;
    }
    @keyframes fadeUp {
      to {
        transform: translateY(0);

        opacity: 1;
      }
    }
    [data-animation='fadeDown'] {
      transform: translateY(-1rem);

      opacity: 0;
    }
    [data-animation='fadeDown'].animate {
      animation-name: fadeDown;
    }
    @keyframes fadeDown {
      to {
        transform: translateY(0);

        opacity: 1;
      }
    }
    [data-animation-order='0'] {
      animation-delay: 0s;
    }
    [data-animation-order='1'] {
      animation-delay: .15s;
    }
    [data-animation-order='2'] {
      animation-delay: .3s;
    }
    [data-animation-order='3'] {
      animation-delay: .45s;
    }
    [data-animation-order='4'] {
      animation-delay: .6s;
    }
    [data-animation-order='5'] {
      animation-delay: .75s;
    }
    [data-animation-order='6'] {
      animation-delay: .9s;
    }
    [data-animation-order='7'] {
      animation-delay: 1.05s;
    }
    [data-animation-order='8'] {
      animation-delay: 1.2s;
    }
    [data-animation-order='9'] {
      animation-delay: 1.35s;
    }
    [data-animation-order='10'] {
      animation-delay: 1.5s;
    }
  }
}
*/

.brand-item {
  font-size: 2.125rem;

  display: block;

  padding: .5rem 1.5rem;

  transition: all .2s ease-in-out;
  transition-property: border-color;
  text-align: center;

  color: #828a91;
  border: 1px solid rgba(0, 0, 0, .1);
}

.brand-item > img {
  width: auto;
  max-width: 100%;
  height: 2.125rem;

  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.brand-item:hover,
.brand-item:focus,
.brand-item:active,
.brand-item:active:focus {
  text-decoration: none;

  color: #828a91;
  border-color: #d2aa78;
}

.error-img {
  max-width: 50%;
}

@media (min-width: 768px) {
  .error-img {
    max-width: 100%;
  }
}

.error-img-body {
  fill: #adb5bd;
}

.error-img-paper {
  fill: #f8f9fa;
}

.error-img-line {
  stroke: #ced4da;
  stroke-width: 1;
}

.error-img-bottom {
  fill: #828a91;
}

.error-img-stripe {
  stroke: #ced4da;
  stroke-width: 2.5;
}

.error-img-light-success {
  fill: #5cbc72;
}

.error-img-light-warning {
  fill: #ffd043;
}

.error-img-light-danger {
  fill: #e46572;
}

.faq-item {
  padding: 1rem 0;
}

.faq-item-heading {
  display: flex;

  transition: all .2s ease-in-out;

  color: #212529;

  align-items: center;
}

.faq-item-heading:before {
  font-weight: bold;

  display: inline-flex;

  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;

  content: '+';
  transition: inherit;

  color: white;
  background-color: #343a40;

  align-items: center;
  justify-content: center;
}

.faq-item-heading:hover {
  text-decoration: none;

  color: #be863f;
}

.faq-item-heading:hover:before {
  background-color: #be863f;
}

.faq-item-heading[aria-expanded='true']:before {
  content: '-';
}

.faq-item-content {
  color: #828a91;
}

.faq-item-content-inner {
  padding-top: 1rem;
}

.faq-item + .faq-item {
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.footer-brand-svg {
  width: auto;
  width: 5.46191rem;
  height: 1.85938rem;
}

.footer-brand-img {
  width: auto;
  height: 1.85938rem;
}

.footer-links {
  padding-left: 0;

  list-style-type: none;
}

@media (min-width: 768px) {
  .footer-links {
    display: flex;
  }
}

.footer-links > li + li {
  margin: .5rem 0 0 0;
}

@media (min-width: 768px) {
  .footer-links > li + li {
    margin: 0 0 0 1.5rem;
  }
}

.fp-section .section {
  position: static;
}

@media (max-width: 767.98px) {
  .fp-section:not(:first-child) .section-top {
    padding-top: 5rem;
  }
}

.header {
  position: relative;

  padding-bottom: 100%;
}

@media (min-width: 768px) {
  .header {
    padding-bottom: 25%;
  }
}

.header-img {
  position: absolute;

  width: 100%;
  height: 100%;

  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.icon-2x {
  font-size: 2em !important;
}

.icon path {
  fill: none;
  stroke: currentColor;
  stroke-width: 6;
  stroke-linecap: round;
  stroke-linejoin: round;
}

@media all and (-ms-high-contrast: none) {
  .icon-offset path {
    animation: none !important;

    stroke-dashoffset: 0 !important;
  }
}

@supports (-ms-ime-align: auto) {
  .icon-offset path {
    animation: none !important;

    stroke-dashoffset: 0 !important;
  }
}

.icon-person path {
  stroke-dasharray: 328;
}

.icon-person.icon-offset path {
  stroke-dashoffset: 328;
}

.icon-businessman path {
  stroke-dasharray: 490;
}

.icon-businessman.icon-offset path {
  stroke-dashoffset: 490;
}

.icon-envelope path {
  stroke-dasharray: 477;
}

.icon-envelope.icon-offset path {
  stroke-dashoffset: 477;
}

.icon-lock path {
  stroke-dasharray: 441;
}

.icon-lock.icon-offset {
  stroke-dashoffset: 441;
}

.icon-diamond path {
  stroke-dasharray: 688;
}

.icon-diamond.icon-offset path {
  stroke-dashoffset: 688;
}

.icon-layers path {
  stroke-dasharray: 514;
}

.icon-layers.icon-offset path {
  stroke-dashoffset: 514;
}

.icon-edit path {
  stroke-dasharray: 659;
}

.icon-edit.icon-offset path {
  stroke-dashoffset: 659;
}

.icon-comment path {
  stroke-dasharray: 406;
}

.icon-comment.icon-offset path {
  stroke-dashoffset: 406;
}

.icon-at path {
  stroke-dasharray: 463;
}

.icon-at.icon-offset path {
  stroke-dashoffset: 463;
}

.icon-support path {
  stroke-dasharray: 539;
}

.icon-support.icon-offset path {
  stroke-dashoffset: 539;
}

.icon-truck path {
  stroke-dasharray: 582;
}

.icon-truck.icon-offset path {
  stroke-dashoffset: 582;
}

.icon-case path {
  stroke-dasharray: 510;
}

.icon-case.icon-offset path {
  stroke-dashoffset: 510;
}

.icon-clock path {
  stroke-dasharray: 381;
}

.icon-clock.icon-offset path {
  stroke-dashoffset: 381;
}

.icon-cart path {
  stroke-dasharray: 486;
}

.icon-cart.icon-offset path {
  stroke-dashoffset: 486;
}

.icon-marker path {
  stroke-dasharray: 335;
}

.icon-marker.icon-offset path {
  stroke-dashoffset: 335;
}

.icon-tick path {
  stroke-dasharray: 384;
}

.icon-tick.icon-offset path {
  stroke-dashoffset: 384;
}

.icon-building path {
  stroke-dasharray: 700;
}

.icon-building.icon-offset path {
  stroke-dashoffset: 700;
}

.icon-camera path {
  stroke-dasharray: 474;
}

.icon-camera.icon-offset path {
  stroke-dashoffset: 474;
}

.quote {
  font-size: 1.125rem;

  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .quote {
    flex-direction: row;
  }
}

.quote:before {
  font-family: 'Objective', sans-serif;
  font-size: 5rem;
  line-height: 1.35;

  min-width: 1em;

  content: open-quote;
}

.quote:after {
  content: close-quote;
  transform: scale(0);
}

.quote-blockquote {
  margin-bottom: 0;
}

.quote-blockquote-content {
  font-size: inherit;
}

.quote-blockquote-footer {
  font-size: .9375rem;

  display: flex;

  align-items: center;
}

.quote-blockquote-footer > img {
  width: 3.5em;
  height: auto;
  margin-right: 1em;

  border-radius: 1.75em;
}

@supports ((-o-object-fit: cover) or (object-fit: cover)) {
  .quote-blockquote-footer > img {
    height: 3.5em;

    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
  }
}

.quote-sm {
  font-size: .9375rem;
}

.quote-sm .quote-blockquote-footer {
  font-size: .8125rem;
}

.map {
  position: relative;

  padding-bottom: 100%;
}

@media (min-width: 768px) {
  .map {
    padding-bottom: 25%;
  }
}

.map-container {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.section {
  position: relative;

  padding: 5rem 0;
}

@media (min-width: 768px) {
  .section {
    padding: 7.5rem 0;
  }
}

.section .container {
  position: relative;
  z-index: 1;
}

.section-top {
  padding-top: calc(5rem + 5.8125rem);
}

@media (min-width: 768px) {
  .section-top {
    padding-top: calc(7.5rem + 5.8125rem);
  }
}

@media (min-width: 768px) {
  .section-full .container .row {
    min-height: calc(100vh - 7.5rem * 2);
  }
  .section-full .container .row:before {
    min-height: inherit;

    content: '';
  }
  .section-full.section-top > .container > .row {
    min-height: calc(100vh - 7.5rem * 2 - 5.8125rem);
  }
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidenav {
    position: -webkit-sticky;
    position: sticky;
    top: 7.8125rem;

    overflow-y: auto;

    max-height: calc(100vh - 5.8125rem - 2rem * 2);
  }
}

.slider-item {
  width: 100%;
  height: auto;
  padding: 0 .375rem;
}

.slider-highlight .slider-item.is-selected:after {
  position: absolute;
  top: 0;
  right: .375rem;
  left: .375rem;

  content: '';

  border-top: 3px solid #d2aa78;
}

.slider-fade .slider-item {
  left: 0 !important;

  transition: opacity .15s linear;

  opacity: 0;
}

.slider-fade .slider-item:not(.is-selected) {
  z-index: -1;
}

.slider-fade .slider-item.is-selected {
  opacity: 1;
}

.slider .flickity-button {
  font-size: 1.125rem;

  width: 2.5rem;
  height: 2.5rem;

  transition: all .2s ease-in-out;
  transform: none;

  opacity: 1;
  color: #212529;
  border-radius: 0;
  background-color: white;
}

.slider .flickity-button:hover,
.slider .flickity-button:focus,
.slider .flickity-button:active,
.slider .flickity-button:active:focus {
  opacity: 1;
  color: white;
  background-color: #d2aa78;
  box-shadow: none;
}

.slider .flickity-button-icon {
  fill: currentColor;
}

.slider .flickity-button.previous {
  top: auto;
  right: calc(2.5rem + .75rem / 2);
  bottom: 0;
  left: auto;
}

.slider .flickity-button.next {
  top: auto;
  right: .375rem;
  bottom: 0;
  left: auto;
}

.slider-fade .flickity-slider {
  transform: none !important;
}

.slider-control {
  transition: all .2s ease-in-out;
  transition-property: color;
}

.slider-control:hover,
.slider-control:focus,
.slider-control:active,
.slider-control:focus:active {
  text-decoration: none;
}

.social-links {
  padding-left: 0;

  list-style: none;
}

.social-links > li {
  display: inline-block;
}

.social-links > li + li {
  margin-left: 1.5rem;
}

.spec-img > img {
  max-width: 100%;
  height: auto;
  padding: 0 .75rem;
}

@media (min-width: 768px) {
  .spec-img > img {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .spec-img {
    position: absolute;
    top: 0;
    right: calc(50% + 3rem / 4);
    bottom: 0;
    left: 0;
  }
  .spec-img > img {
    width: auto;
    height: 100%;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .spec-img > img {
      width: 100%;

      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center center;
      object-position: center center;
    }
  }
  .spec-img:before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 7.5rem;
    height: 7.5rem;

    content: '';
    transform: translate(-50%, -50%);

    border: 3.25rem solid rgba(210, 170, 120, .25);
    border-radius: 50%;
    background-color: #d2aa78;
    background-clip: padding-box;
  }
  .spec-img:after {
    position: absolute;
    top: 50%;
    left: 50%;

    width: calc(50% + 1.5rem);

    content: '';
    transform: translateY(-50%);

    border-top: 2px solid #d2aa78;
  }
}

@media (min-width: 1200px) {
  .spec-img:after {
    width: calc(50% + 3rem);
  }
}

@media (min-width: 768px) {
  .spec-body {
    padding: 7.5rem 0;
  }
}

.spec-list {
  padding-left: 0;

  list-style-type: none;
}

.spec-list > li {
  display: flex;

  padding: .5rem 0;
}

.spec-list > li > span {
  width: 100%;
}

.spec-list > li > span:first-child,
.spec-list > li > span:last-child {
  width: auto;

  white-space: nowrap;
}

.spec-list > li > span:nth-child(2) {
  background-image: linear-gradient(to right, transparent 90%, #828a91 90%, #828a91);
  background-repeat: repeat-x;
  background-position: bottom left;
  background-size: 10px 1px;
}

.stats-item {
  position: relative;
}

.stats-item:before {
  display: block;

  width: .5rem;
  height: .5rem;
  margin: 0 auto 1.5rem;

  content: '';

  background-color: #d2aa78;
}

.stats-item-value {
  font-family: 'Objective', sans-serif;
  font-size: 1.625rem;
}

.stats-item-title {
  font-size: .6875rem;
  font-weight: 500;

  text-transform: uppercase;
}

.testimonial-slider-item {
  width: 100%;
  padding: 0 .75rem;

  transition: opacity .15s linear;
}

@media (min-width: 768px) {
  .testimonial-slider-item {
    padding: 0 1.5rem;
  }
}

@media (min-width: 992px) {
  .testimonial-slider-item {
    width: 50%;
  }
}

.testimonial-slider-item:not(.is-selected) {
  opacity: .25;
}

.testimonial-slider-item-inner {
  padding: 1.5rem;

  border: 1px solid #d2aa78;
}

@media (min-width: 768px) {
  .testimonial-slider-item-inner {
    padding: 2.5rem 2rem;
  }
}

.title {
  letter-spacing: .025em;
  text-transform: uppercase;
}

.title:after {
  display: block;

  width: 2rem;
  margin: 1rem 0 1.5rem;

  content: '';

  border-top: 2px solid #d2aa78;
}

//
// theme-brown.scss
// Main stylesheet file
//

// Primary color
$primary: #d2aa78;

// Incline setup
@import "incline/functions";
@import "incline/variables";
@import "incline/user-variables";

// Incline core
@import "incline/incline";
