//
// error.scss
// Incline component
//

.error {

  // Image
  // =====

  &-img {
    $paper-color:   $gray-100;
    $line-color:   $gray-400;
    $body-color:   $gray-500;
    $bottom-color:   $gray-600;
    $stripe-color:   $line-color;
    $danger-color:   theme-color-level(danger, -3);
    $warning-color: theme-color-level(warning, -3);
    $success-color: theme-color-level(success, -3);

    max-width: 50%;

    @include media-breakpoint-up(md) {
      max-width: 100%;
    }

    // Body

    &-body {
      fill: $body-color;
    }

    // Paper

    &-paper {
      fill: $paper-color;
    }

    // Line

    &-line {
      stroke: $line-color;
      stroke-width: 1;
    }

    // Bottom

    &-bottom {
      fill: $bottom-color;
    }

    // Stripe

    &-stripe {
      stroke: $stripe-color;
      stroke-width: 2.5;
    }

    // Light

    &-light {
      
      &-success {
        fill: $success-color;
      }
      &-warning {
        fill: $warning-color;
      }
      &-danger {
        fill: $danger-color;
      }
    }
    
  }

}