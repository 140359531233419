//
// slider.scss
// Incline component
//

.slider {

  // Item
  // ====

  &-item {
    width: 100%; height: auto;
    padding: 0 $slider-item-gutter-width / 2;

    // Highlight

    @at-root .slider-highlight #{&} {

      // Selected

      &.is-selected {
      
        // Line

        &:after {
          content: '';
          position: absolute;
          top: 0; 
          left: $slider-item-gutter-width / 2; 
          right: $slider-item-gutter-width / 2;
          border-top: 3px solid $primary;
        }
      }
    }

    // Fade

    @at-root .slider-fade #{&} {
      left: 0 !important;
      opacity: 0;
      transition: $transition-fade;

      // Not selected

      &:not(.is-selected) {
        z-index: -1;
      }

      // Selected

      &.is-selected {
        opacity: 1;
      }
    }
  }


  // Buttons
  // =======

  .flickity {

    // Button

    &-button {
      width: $slider-nav-size; height: $slider-nav-size;
      background-color: $slider-nav-bg;
      border-radius: 0;
      font-size: $slider-nav-font-size;
      color: $slider-nav-color;
      transform: none;
      opacity: 1;
      transition: $transition-base;

      // Hover

      &:hover, &:focus, &:active, &:active:focus {
        background-color: $slider-nav-hover-bg;
        color: white;
        opacity: 1;
        box-shadow: none;
      }

      // Icon

      &-icon {
        fill: currentColor;
      }

      // Previous

      &.previous {
        top: auto; bottom: 0;
        left: auto; right: calc(#{$slider-nav-size} + #{$slider-item-gutter-width} / 2);
      }

      // Next

      &.next {
        top: auto; bottom: 0;
        left: auto; right: $slider-item-gutter-width / 2;
      }
    }
  }


  // Fade
  // ====

  &-fade {

    // Flickity

    .flickity {

      // Slider

      &-slider {
        transform: none !important;
      }
    }
  }


  // Control
  // =======

  &-control {
    transition: $transition-base;
    transition-property: color;
    
    // Hover

    &:hover, &:focus, &:active, &:focus:active {
      text-decoration: none;
    }
  }
}