//@import 'login-page';
//@import 'common-page';

@import 'lead-magnet.scss';

.cart-block {
  background-color: #fff;
  padding: 40px 60px;

  @include media-breakpoint-only(xs) {
    padding: 40px 30px;
  }
}
