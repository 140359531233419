.image-slide {
  padding-bottom: 20px;

  .text-block {
    font-size: 16px;
    margin-bottom: 0;
  }

  .image-block {
    margin-bottom: 10px;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 350px;
    }
  }
}
