//
// faq.scss
// Incline component
//

.faq {

  // Item
  // ====

  &-item {
    padding: $faq-item-padding;

    // Heading

    &-heading {
      display: flex;
      align-items: center;
      color: $body-color;
      transition: $transition-base;

      // Sign

      &:before {
        $size: 1.5rem;
        content: '+';
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $size; height: $size;
        min-width: $size;
        margin-right: 1rem;
        background-color: $dark;
        font-weight: bold;
        color: white;
        transition: inherit;
      }

      // Hover

      &:hover {
        text-decoration: none;
        color: $link-hover-color;

        &:before {
          background-color: $link-hover-color;
        }
      }

      // Expanded

      &[aria-expanded="true"] {

        &:before {
          content: '-';
        }
      }
    }

    // Content

    &-content {
      color: $text-muted;

      // Inner

      &-inner {
        padding-top: 1rem;
      }
    }

    // Next

    + .faq-item {
      border-top: 1px solid $border-color;
    }
  }
}