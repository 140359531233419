.lead-magnet {
    &__hero .container, .navbar .container {
        @include media-breakpoint-up(xl) {
            max-width: 1300px;
        }
    }

    &__hero {
        // height: 100vh;
        min-height: 500px; // Fallback for browsers that don't support CSS max()
        // Both sass and css have a max() function, but sass is case-sensitive while css isn't, so I'm using MAX() to force it to use the css function
        min-height: MAX(500px, MIN(100vh, 1000px));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow-x: hidden;

        // Image container
        .img-effect-dotted {
            padding: 5% 5% 0 5%;
        }
    }

    &__hero-icons {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        width: 100%;

        &__icon {
            width: 100%;
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            text-align: center;

            svg {
                width: 26px;
                height: 26px;
                margin-bottom: 8px;
            }

            @include media-breakpoint-up(sm) {
                padding: 24px 12px;
                flex-direction: row;

                svg {
                    margin-right: 8px;
                    margin-bottom: 0;
                }
            }

            @include media-breakpoint-up(lg) {
                svg {
                    width: 30px;
                    height: 30px;
                }

                span {
                    font-size: 1rem;
                }
            }
        }
    }

    &__steps {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__item {
            text-align: center;
            margin-top: 24px;
            max-width: 350px;
            width: 100%;

            @media (min-width: 768px) {
                margin-top: 0;
            }

            &__icon {
                width: 100px;
                height: 100px;

                @media (min-width: 576px) {
                    width: 150px;
                    height: 150px;
                }

                @media (min-width: 992px) {
                    width: 200px;
                    height: 200px;
                }
            }

            &__text {
                margin-top: 8px;
                font-size: 18px;
                font-weight: $font-weight-bold;

                @media (min-width: 992px) {
                    font-size: 20px;
                }
            }
        }
    }

    &__banner {
        display: flex;
        background: #efbf3c;

        &__container {
            padding: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media (min-width: 576px) {
                flex-direction: row;
            }
        }

        &__text {
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            font-size: 16px;
            margin-bottom: 24px;
            text-align: center;

            @media (min-width: 576px) {
                margin-right: 24px;
                margin-bottom: 0;
            }

            @media (min-width: 768px) {
                font-size: 20px;
            }
        }

        &__button {
        }
    }
}