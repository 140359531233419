//
// spec.scss
// Incline component
//

.spec {
  
  // Image
  // =====

  &-img {

    > img {
      max-width: 100%; height: auto;
      padding: 0 $grid-gutter-width / 4;

      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0; bottom: 0; left: 0; right: calc(50% + #{$grid-gutter-width} / 4);  
      
      > img {
        height: 100%; width: auto;

        @supports (object-fit: cover) {
          width: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      // Circle

      &:before {
        content: '';
        position: absolute;
        top: 50%; left: 50%;
        width: 7.5rem; height: 7.5rem;
        border-radius: 50%;
        border: 3.25rem solid fade-out($primary, .75);
        background-color: $primary;
        background-clip: padding-box;
        transform: translate(-50%, -50%);
      }

      // Line

      &:after {
        content: '';
        position: absolute;
        top: 50%; left: 50%;
        width: calc(50% + #{$grid-gutter-width / 2});
        border-top: 2px solid $primary;
        transform: translateY(-50%);
      }
    }
    @include media-breakpoint-up(xl) {

      // Line

      &:after {
        width: calc(50% + #{$grid-gutter-width});
      }
    }
  }

  // Body
  // ====

  &-body {

    @include media-breakpoint-up(md) {
      padding: $section-padding-y 0;  
    }
  }

  // List
  // ====

  &-list {
    list-style-type: none;
    padding-left: 0;

    > li {
      display: flex;
      padding: .5rem 0;
      
      > span {
        width: 100%;

        &:first-child,
        &:last-child {
          width: auto;
          white-space: nowrap;
        }

        // Dots

        &:nth-child(2) {
          background: {
            repeat: repeat-x;
            image: linear-gradient(to right, transparent 90%, #{$text-muted} 90%, #{$text-muted});
            position: bottom left;
            size: 10px 1px;
          };
        }
      }
    }
  }
}