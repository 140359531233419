//
// type.scss
// Extended from Bootstrap
//

// Import fonts
// ============

// Objective

@font-face {
  font-family: 'Objective';
  font-weight: 600;
  src: url('../fonts/Objective/Objective-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Objective';
  font-weight: 500;
  src: url('../fonts/Objective/Objective-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Objective';
  font-weight: 400;
  src: url('../fonts/Objective/Objective-Regular.woff2') format('woff2');
}


// Headings
// ========

// General

h1, h2, h3, h4, h5, h6 {
  letter-spacing: $headings-letter-spacing;
}

// Heading font size for small devices

@include media-breakpoint-down(sm) {
  h1 { font-size: $h1-font-size-sm }
  h2 { font-size: $h2-font-size-sm }
  h3 { font-size: $h3-font-size-sm }
  h4 { font-size: $h4-font-size-sm }
  h5 { font-size: $h5-font-size-sm }
  h6 { font-size: $h6-font-size-sm }
}

// Lead
// ====

.lead {
  line-height: $lead-line-height;
}


// Text
// ====

.text {

  // Extra small

  &-xs {
    font-size: $font-size-xs;
  }

  // Small

  &-sm {
    font-size: $font-size-sm;
  }

  // Large

  &-lg {
    font-size: $font-size-lg;
  }

  &-xl {
    font-size: 1.25rem;
  }

  @include media-breakpoint-up(sm) {
    &-sm-xs {
      font-size: $font-size-xs;
    }

    &-sm-sm {
      font-size: $font-size-sm;
    }

    &-sm-lg {
      font-size: $font-size-lg;
    }

    &-sm-xl {
      font-size: 1.25rem;
    }
  }

  // Vertical

  &-vertical {
    position: relative;
    transform-origin: right bottom;

    @include media-breakpoint-up(md) {
      transform: rotate(-90deg) translateX(50%) translateX(3rem);
      white-space: nowrap;  
    }

    > span {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%; right: calc(100% + 1rem);
        border-top: 2px solid;
        width: 2rem;
        transform: translateY(50%);
      }
    }
  }
  
  // White muted

  &-white {

    &.text-muted {
      color: $text-white-muted !important;
    }
  }

  // Zig-zag

  &-zigzag {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0; right: 0; top: 100%;
      height: $text-zigzag-size;
      background: {
        repeat: repeat;
        image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='10'><path d='M0 0 L 10 9 L 20 1' stroke='#{$text-zigzag-border-color}' stroke-width='2' fill='none'></path></svg>");
        size: $text-zigzag-size * 2 $text-zigzag-size;
      }
    }
  }
}


// Blockquote
// ==========

.blockquote {
  margin-bottom: $paragraph-margin-bottom;
  padding-left: 1.5rem;
  border-left: 1px solid $primary;

  > footer {
    font-size: $font-size-sm;

    > img {
      width: $blockquote-img-size; height: auto;
      margin-right: 1em;
      border-radius: ($blockquote-img-size / 2);

      @supports( object-fit: cover ) {
        height: $blockquote-img-size;
        object-fit: cover;
        object-position: center center;
      }
    }
  }
}


// List
// ====

.list {

  // Iconed

  &-iconed {
    list-style-type: none;
    margin-bottom: $paragraph-margin-bottom;
    padding-left: 0;
    text-align: left;

    li {
      display: flex;

      // Next

      + li {
        margin-top: 1em;
      }

      // Arrow

      &:before {
        content: '\f105';
        margin-right: 1em;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: $primary;
      }
    }
  }
}


// Code
// ====

.code {
  max-height: 20rem;
}


// Selection
// =========

::selection {
  background-color: $selection-bg;
  color: $selection-color;
}
