// START LOGOWATERMARK
// Still need to see this in action to know what it looks
#logo_watermark {
  position: relative;
}
#logo_watermark::after {
  content: "";
  background: var(--logo);
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
}
// END LOGOWATERMARK

.swot-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  & > *:first-child {
    margin-bottom: 3rem;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;

    & > *:first-child {
      margin-right: 1.5rem;
      margin-bottom: 0;
    }

    & > * + * {
      margin-left: 1.5rem;
    }
  }

  &.swot-row--single {
    & > *:not(.swot-full-bleed) {
      max-width: 798px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.swot-col {
  width: 100%;
  margin-bottom: 3rem;
}

.swot-hero-bg {
  background-position: center top;

  @include media-breakpoint-up(sm) {
    background-image: url("../img/backgrounds/home-hero-bg2.jpg");
  }

  @include media-breakpoint-down(xs) {
    background-image: url("../img/backgrounds/home-hero-bg2-mobile.jpg");
  }
}

.swot-first-heading {
  font-size: 32px;

  @include media-breakpoint-up(md) {
    font-size: 48px;
    line-height: 1.125;
  }
}

.swot-blockquote {
  margin-bottom: 1.5rem;
  padding-left: 1.5rem;
  border-left: 1px solid #f5ba4b;
}
