//
// animation.scss
// Incline component
//

@if ( $enable-animation ) {

  // Disable animations on mobile

  @include media-breakpoint-up(md) {

    // Disable animations on IE

    @supports(--css: variables) {

      // Animation
      // =========

      [data-toggle="animation"] {
        animation-duration: $animation-duration;
        animation-timing-function: $animation-timing-function;
        animation-fill-mode: forwards;
      }

      // Fade in

      [data-animation="fadeIn"] {
        opacity: 0;

        &.animate {
          animation-name: fadeIn;

          @keyframes fadeIn {
            to {
              opacity: 1;
            }
          }
        }
      }

      // Fade up

      [data-animation="fadeUp"] {
        transform: translateY(1rem);
        opacity: 0;

        &.animate {
          animation-name: fadeUp;

          @keyframes fadeUp {
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }

      // Fade down

      [data-animation="fadeDown"] {
        transform: translateY(-1rem);
        opacity: 0;

        &.animate {
          animation-name: fadeDown;

          @keyframes fadeDown {
            to {
              transform: translateY(0);
              opacity: 1;
            }
          }
        }
      }


      // Order
      // =====

      @for $i from 0 through 10 {

        [data-animation-order="#{$i}"] {
          animation-delay: $animation-delay * $i;
        }
      }

    }
  }
}